import Breadcrumb from "../../../../components/breadcrumb";
import BaseServidores from "./baseServidores";
import { getDashboardProcessoSeletivoServidores } from "../../../../services/dashboard";
import { useQuery } from 'react-query';
import Spinner from "../../../../components/spinner";
import CargoBarChart from "./components/graficoCargos";

const HomeServidores = () => {
  const { data, isLoading } = useQuery('getDashboardProcessoSeletivoServidores', getDashboardProcessoSeletivoServidores, {
    retry: 3
  });

  // Map of cargos with titles and corresponding data keys
  const cargos = [
    // { title: "AGENTE DE SERVIÇOS GERAIS", key: "cargo1" },
    // { title: "AUXILIAR DE DISCIPLINA", key: "cargo2" },
    { title: "MERENDEIRA", key: "cargo3" },
    { title: "MONITOR DE TRANSPORTE ESCOLAR", key: "cargo4" },
    { title: "MOTORISTA D", key: "cargo5" },
    // { title: "NUTRICIONISTA", key: "cargo6" },
    // { title: "OFICIAL ADMINISTRATIVO", key: "cargo7" },
    // { title: "ORIENTADOR EDUCACIONAL", key: "cargo8" },
    // { title: "ORIENTADOR PEDAGÓGICO", key: "cargo9" },
    // { title: "PROFESSOR I", key: "cargo10" },
    // { title: "PROFESSOR II", key: "cargo11" },
    { title: "SECRETÁRIO ESCOLAR", key: "cargo12" },
    // { title: "VIGIA", key: "cargo13" },
    { title: "SUPERVISOR EDUCACIONAL", key: "cargo14" },
  ];

  const chartData = cargos.reduce((acc, cargo) => {
    acc[cargo.title] = data?.[cargo.key] || 0;
    return acc;
  }, {});

  return (
    <BaseServidores>
      <Breadcrumb title={'Informações Gerais'} itens={['Processo Seletivo Servidores', 'Dashboard']} />
      {
        isLoading ? (
          <Spinner />
        ) : (
          <>
            <div className="row">
              {cargos.map((cargo, index) => (
                <div className="col-xl-6" key={index}>
                  <div className="card widget widget-stats">
                    <div className="card-body">
                      <div className="widget-stats-container d-flex">
                        <div className="widget-stats-icon widget-stats-icon-primary">
                          <i className="material-icons-outline md-groups"></i>
                        </div>
                        <div className="widget-stats-content flex-fill">
                          <span className="widget-stats-title">{cargo.title}</span>
                          <span className="widget-stats-amount">{data?.[cargo.key]}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="row">
              <CargoBarChart data={chartData} />
            </div>
          </>
        )}
    </BaseServidores>
  );
};

export default HomeServidores;
