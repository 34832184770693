import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  TextField,
  Typography,
} from "@mui/material";
import InputMask from "react-input-mask";
import { escolaridadesOptions } from "../data/escolaridadesOptions";
import { isValidCPF } from "../../../../../utils/isValidCpf";
import { getUnidades } from "../../../../../services/unidades";
import { escolhasBinarias } from "../data/escolhasBinarias";
import { unidadesEstudo } from "../data/unidadesEstudo";

const Etapa2 = ({ data, onChange, setValidate }) => {
  const [unidadesOptions, setUnidadesOptions] = useState([]);

  useEffect(() => {
    // Define the validation function for this step
    setValidate(() => () => {
      const isCPFValid = isValidCPF(data.passo2CpfAluno);
      const isUnidadeValid =
        data.passo2EstudanteRede === true ? !!data.passo2UnidadeRede : true;

      return (
        data.passo2Deficiente !== null &&
        data.passo2Escolaridade &&
        isCPFValid &&
        data.passo2EstudanteRede !== null &&
        isUnidadeValid
      );
    });
  }, [data, setValidate]);

  useEffect(() => {
    // Fetch unidades if Estudante da Rede is true
    if (data.passo2EstudanteRede) {
      setUnidadesOptions(unidadesEstudo);
    } else {
      setUnidadesOptions([]);
      onChange("passo2UnidadeRede", 0);
    }
  }, [data.passo2EstudanteRede]);


  const escolaridadeOptions = escolaridadesOptions[data?.passo1Curso] || [];

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h6" gutterBottom>
        Informações Iniciais
      </Typography>

      {/* Deficiente */}
      <Autocomplete
        options={escolhasBinarias}
        getOptionLabel={(option) => option.label}
        fullWidth
        renderInput={(params) => (
          <TextField
            {...params}
            label="O aluno possui deficiência?"
            variant="outlined"
            margin="normal"
            required
          />
        )}
        value={escolhasBinarias.find((option) => option.id === data.passo2Deficiente) || null}
        onChange={(e, value) =>
          onChange("passo2Deficiente", value ? value.id : null)
        }
      />

      {/* Escolaridade */}
      <Autocomplete
        options={escolaridadeOptions}
        getOptionLabel={(option) => option.label}
        fullWidth
        renderInput={(params) => (
          <TextField
            {...params}
            label="Ano de escolaridade do aluno em 2025"
            variant="outlined"
            margin="normal"
            required
          />
        )}
        value={
          escolaridadeOptions.find(
            (option) => option.id === data.passo2Escolaridade
          ) || null
        }
        onChange={(e, value) =>
          onChange("passo2Escolaridade", value ? value.id : "")
        }
      />

      {/* CPF Field */}
      <InputMask
        mask="999.999.999-99"
        value={data?.passo2CpfAluno}
        onChange={(e) => onChange("passo2CpfAluno", e.target.value)}
      >
        {() => (
          <TextField
            label="Digite o CPF do aluno"
            fullWidth
            margin="normal"
            variant="outlined"
            required
            error={data?.passo2CpfAluno && !isValidCPF(data?.passo2CpfAluno)}
            helperText={
              data?.passo2CpfAluno &&
              !isValidCPF(data?.passo2CpfAluno) &&
              "CPF inválido"
            }
          />
        )}
      </InputMask>

      {/* Estudante da Rede */}
      <Autocomplete
        options={escolhasBinarias}
        getOptionLabel={(option) => option.label}
        fullWidth
        disabled={true}
        renderInput={(params) => (
          <TextField
            {...params}
            label="O estudante é da rede municipal de ensino?"
            variant="outlined"
            margin="normal"
            
          />
        )}
        value={escolhasBinarias.find((option) => option.id === data.passo2EstudanteRede) || null}
        onChange={(e, value) =>
          onChange("passo2EstudanteRede", value ? value.id : null)
        }
      />

      {/* Unidade da Rede - Conditional Field */}
      {data.passo2EstudanteRede && (
        <Autocomplete
          options={unidadesOptions}
          getOptionLabel={(option) => option.nome} // Assumes `nome` is the label for unidades
          fullWidth
          renderInput={(params) => (
            <TextField
              {...params}
              label="Unidade da Rede"
              variant="outlined"
              margin="normal"
            />
          )}
          value={
            unidadesOptions.find((option) => option.id === data.passo2UnidadeRede) || null
          }
          onChange={(e, value) =>
            onChange("passo2UnidadeRede", value ? value.id : null)
          }
        />
      )}
    </Box>
  );
};

export default Etapa2;
