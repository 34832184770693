export const distritoOptions = [
    {
        distrito: "1º Distrito",
        nome: "Secretaria Munical de Educação",
        endereco: "Av. Country Clube dos Engenheiros, nº 16, Clube dos Engenheiros, São Pedro da Aldeia"
    },
    {
        distrito: "2º Distrito",
        nome: "Escola Municipal Honorino Coutinho",
        endereco: "Praça de Morro Grande, s/nº - Morro Grande - São Pedro da Aldeia"
    },
    {
        distrito: "3º Distrito",
        nome: "Escola Municipal Professor Pedro Paulo de Bragança Pimentel",
        endereco: "Rua Doutor Renato Lessa, s/nº - Monteiro - São Vicente de Paulo - São Pedro da Aldeia"
    },
    {
        distrito: "4º Distrito",
        nome: "Praça Escola Comandante Sérgio Ribeiro de Vasconcellos",
        endereco: "Rua Heron Domingues, s/nº - Praia Seca - São Pedro da Aldeia"
    },
    {
        distrito: "5º Distrito",
        nome: "Escola Municipal André Gomes dos Santos",
        endereco: "Rua Monte Azurel, s/nº - Bananeiras - São Pedro da Aldeia"
    }
];