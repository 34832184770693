import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Divider,
  Button,
  Container,
  Grid2,
} from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import api from "../../../../../services";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import urlsServidores from "../urlsServidores";

const ServidoresVisualizarFormulario = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState();

  const deficienciaOptions = [
    { key: 1, value: "DEFICIÊNCIA FÍSICA" },
    { key: 2, value: "DEFICIÊNCIA INTELECTUAL / SÍNDROME DE DOWN" },
    { key: 5, value: "SUPERDOTAÇÃO / ALTAS HABILIDADES" },
    { key: 3, value: "TDA/ TDAH/ DISLEXIA/ DISORTOGRAFIA" },
    { key: 4, value: "TRANSTORNO DO ESPECTRO AUTISTA" },
  ];

  const [totalCertificadosCursos, setTotalCertificadosCursos] = useState(0);
  const [totalCertificadosPosGraduacao, setTotalCertificadosPosGraduacao] = useState(0);
  const [totalCertificadosMestrado, setTotalCertificadosMestrado] = useState(0);
  const [totalCertificadosDoutorado, setTotalCertificadosDoutorado] = useState(0);

  useEffect(() => {
    async function fetchData() {
      const response = await api.get(`/processoSeletivo/inscricao/servidor/${id}`);
      setData(response.data.data);
      setTotalCertificadosCursos(
        (response.data.data.anexoCertificados1 !== "" ? 1 : 0) +
        (response.data.data.anexoCertificados2 !== "" ? 1 : 0) +
        (response.data.data.anexoCertificados3 !== "" ? 1 : 0) +
        (response.data.data.anexoCertificados4 !== "" ? 1 : 0) +
        (response.data.data.anexoCertificados5 !== "" ? 1 : 0)
      );
      setTotalCertificadosPosGraduacao(
        (response.data.data.anexoPosGraduacao1 !== "" ? 1 : 0) +
        (response.data.data.anexoPosGraduacao2 !== "" ? 1 : 0) +
        (response.data.data.anexoPosGraduacao3 !== "" ? 1 : 0)
      );
      setTotalCertificadosMestrado(
        (response.data.data.anexoMestrado1 !== "" ? 1 : 0) +
        (response.data.data.anexoMestrado2 !== "" ? 1 : 0)
      );
      setTotalCertificadosDoutorado(
        response.data.data.anexoDoutorado1 !== "" ? 1 : 0
      );

    }
    fetchData();
  }, [id]);

  const renderStatus = (status) => {
    switch (status) {
      case 1:
        return "AGUARDANDO";
      case 2:
        return "HOMOLOGADO";
      case 3:
        return "CONVOCADO";
      case 4:
        return "NÃO HOMOLOGADO";
      case 5:
        return "NÃO COMPARECEU";
      case 6:
        return "RECURSO ACEITO";
      case 7:
        return "RECURSO NEGADO";
      case 8:
        return "CANCELADO";
      default:
        return "";
    }
  };

  const handleDownloadPDF = async () => {
    const content = document.getElementById("printable-content");

    // Use html2canvas with a lower scale for smaller file size
    const canvas = await html2canvas(content, {
      scale: 1, // Reduce scale to lower image resolution
      useCORS: true, // Enable cross-origin for external images
    });

    const imgData = canvas.toDataURL("image/jpeg", 0.90); // Compress image to 75% quality
    const pdf = new jsPDF("portrait", "mm", "a4");

    const pageWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = pdf.internal.pageSize.getHeight();
    const imgWidth = pageWidth;
    const imgHeight = (canvas.height * imgWidth) / canvas.width;

    // Ensure the content fits within a single A4 page
    pdf.addImage(imgData, "JPEG", 0, 0, imgWidth, imgHeight);
    pdf.save("formulario-confirmacao.pdf");
  };


  return (
    <Container
      maxWidth="lg"
      sx={{
        padding: 4,
        backgroundColor: "#fff",
        borderRadius: 2,
        boxShadow: 3,
        marginY: 4,
      }}
    >
      <Box
        id="printable-content"
        sx={{
          width: "100%",
          padding: 2,
          fontSize: "10px",
          lineHeight: "1.5",
        }}
      >
        {/* Header */}
        <Box display="flex" justifyContent="center" mb={2}>
          <img
            src="/assets/images/prefeituras/seduc.png"
            alt="Logo"
            width="100"
            height="75"
          />
        </Box>
        <Typography variant="h6" align="center">
          PREFEITURA MUNICIPAL DE ARARUAMA
        </Typography>
        <Typography variant="subtitle1" align="center">
          SECRETARIA DE EDUCAÇÃO
        </Typography>
        <Divider sx={{ marginY: 2 }} />
        <Typography variant="h5" align="center" gutterBottom>
          FORMULÁRIO DE CONFIRMAÇÃO DE CADASTRO
        </Typography>
        <Divider sx={{ marginY: 2 }} />

        {/* Main Form */}
        <Grid container spacing={1}>
          {/* Basic Information */}
          <Grid item xs={6}>
            <Typography>
              <strong>ID:</strong> {data?.id}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>
              <strong>Data e Hora:</strong>{" "}
              {data?.criacao &&
                `${data.criacao.split("T")[0].split("-").reverse().join("/")} às ${
                  data.criacao.split("T")[1].split(".")[0]
                }`}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>
              <strong>Nome:</strong> {data?.nome}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>
              <strong>Nacionalidade:</strong> {data?.nacionalidade}
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography>
              <strong>PcD:</strong> {data?.deficiente ? "SIM" : "NÃO"}
            </Typography>
          </Grid>

          {data?.deficiente && (
              <Grid item xs={6}>
              <Typography>
                <strong>Deficiência:</strong> {deficienciaOptions.find((d) => d.key === data?.deficiencia)?.value}
              </Typography>
            </Grid>
          )}

        


          {/* Address Section */}
          <Grid item xs={12}>
            <Typography>
              <strong>Endereço:</strong> {data?.endereco}, {data?.numero}{" "}
              {data?.complemento}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography>
              <strong>Bairro:</strong> {data?.bairro}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography>
              <strong>Cidade:</strong> {data?.cidade}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography>
              <strong>Estado:</strong> {data?.estado}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>
              <strong>CEP:</strong> {data?.cep}
            </Typography>
          </Grid>

          {/* Contact Information */}
          <Grid item xs={6}>
            <Typography>
              <strong>Telefone Residencial:</strong> {data?.telefoneResidencial}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>
              <strong>Telefone Recado:</strong> {data?.telefoneRecado}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>
              <strong>Celular:</strong> {data?.telefoneCelular}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>
              <strong>Email:</strong> {data?.email}
            </Typography>
          </Grid>

          {/* Personal Details */}
          <Grid item xs={6}>
            <Typography>
              <strong>Data de Nascimento:</strong>{" "}
              {data?.dataNascimento &&
                data?.dataNascimento.split("T")[0].split("-").reverse().join("/")}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>
              <strong>Sexo:</strong> {data?.sexo === 2 ? "MASCULINO" : "FEMININO"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>
              <strong>RG:</strong> {data?.numeroRg}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>
              <strong>CPF:</strong> {data?.cpf}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>
              <strong>Nome do Pai:</strong> {data?.nomeDoPai}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>
              <strong>Nome da Mãe:</strong> {data?.nomeDaMae}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>
              <strong>Nível de Escolaridade:</strong>{" "}
              {data?.nivelDeEscolaridade === 1
                ? "FUNDAMENTAL INCOMPLETO"
                : data?.nivelDeEscolaridade === 2
                ? "FUNDAMENTAL COMPLETO"
                : data?.nivelDeEscolaridade === 3
                ? "MÉDIO INCOMPLETO"
                : data?.nivelDeEscolaridade === 4
                ? "MÉDIO COMPLETO"
                : ""}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>
              <strong>Cargo:</strong>{" "}
              {data?.cargo === 3
                ? "MERENDEIRA"
                : data?.cargo === 4
                ? "MONITOR DE TRANSPORTE ESCOLAR"
                : data?.cargo === 5 
                ? "MOTORISTA CLASSE D"
                : data?.cargo === 12 
                ? "SECRETÁRIO ESCOLAR" 
                : data?.cargo === 14 ? "SUPERVISOR EDUCACIONAL" : ""}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>
              <strong>Status:</strong> {renderStatus(data?.status)}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>
              <strong>Motivo:</strong> {data?.motivo || "N/A"}
            </Typography>
          </Grid>
        </Grid>

        <Divider sx={{ marginY: 2 }} />

        {/* Attachments */}
        <Typography variant="h6" gutterBottom>
          ANEXOS
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography>
              <strong>Comprovante Escolaridade:</strong>{" "}
              {data?.anexoComprovanteEscolaridade ? "ENVIADO" : "NÃO ENVIADO"}
            </Typography>
          </Grid>
          {(data?.anexoComprovanteExperiencia !== "") && (
            <Grid item xs={6}>
            <Typography>
              <strong>Comprovante de Experiência:</strong> {data?.anexoComprovanteExperiencia ? "ENVIADO" : "NÃO ENVIADO"}
            </Typography>
          </Grid>
          )}
          <Grid item xs={6}>
            <Typography>
              <strong>RG:</strong> {data?.anexoRg ? "ENVIADO" : "NÃO ENVIADO"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>
              <strong>CPF:</strong> {data?.anexoCpf ? "ENVIADO" : "NÃO ENVIADO"}
            </Typography>
          </Grid>
          

          {(data?.cargo === 5) && (
            <Grid item xs={6}>
            <Typography>
              <strong>CNH:</strong> {data?.anexoCnh ? "ENVIADO" : "NÃO ENVIADO"}
            </Typography>
          </Grid>
          )}

{(data?.deficiente) && (
            <Grid item xs={6}>
            <Typography>
              <strong>LAUDO:</strong> {data?.anexoLaudoMedico ? "ENVIADO" : "NÃO ENVIADO"}
            </Typography>
          </Grid>
          )}



          {(data?.cargo === 14) && (
            <>
            <Grid item xs={6}>
            <Typography>
              <strong>Certificados de Cursos:</strong> {totalCertificadosCursos} de 5 ENVIADOS
            </Typography>
        </Grid>
         <Grid item xs={6}>
         <Typography>
           <strong>Certificados de Pós Graduação:</strong> {totalCertificadosPosGraduacao} de 3 ENVIADOS
         </Typography>
     </Grid>
     <Grid item xs={6}>
         <Typography>
           <strong>Certificados de Mestrado:</strong> {totalCertificadosMestrado} de 2 ENVIADOS
         </Typography>
     </Grid>
     <Grid item xs={6}>
         <Typography>
           <strong>Certificados de Doutorado:</strong> {totalCertificadosDoutorado} de 1 ENVIADOS
         </Typography>
     </Grid>
     </>
          )}

          
          </Grid>
      </Box>

      {/* Action Buttons */}
      <Box display="flex" justifyContent="center" marginTop={3}>
        <Button
          variant="contained"
          color="primary"
          sx={{ marginRight: 2 }}
          onClick={() => navigate(urlsServidores.formulario)}
        >
          Voltar
        </Button>
        <Button variant="contained" color="secondary" onClick={handleDownloadPDF}>
          Imprimir
        </Button>
      </Box>
    </Container>
  );
};

export default ServidoresVisualizarFormulario;
