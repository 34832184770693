import React, { useEffect } from "react";
import {
  Box,
  TextField,
  Typography,
  Grid,
  Autocomplete,
} from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";
import InputMask from "react-input-mask";
import { grauParentescoOptions } from "../data/grauParentescoOptions";
import { tipoTelefoneOptions } from "../data/tipoTelefoneOptions";
import { isValidCPF } from "../../../../../utils/isValidCpf";


const Etapa4 = ({ data, onChange, setValidate }) => {
  useEffect(() => {
    // Define the validation function for this step
    setValidate(() => () => {
      const isEmailValid =
        !data.passo4EmailResponsavel ||
        /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(data.passo4EmailResponsavel);

      const isCpfDifferent =
        data.passo1Curso === 4 ||
        data.passo4CpfResponsavel !== data.passo2CpfAluno;

      return (
        data.passo4NomeResponsavel &&
        data.passo4GrauParentescoResponsavel &&
        data.passo4DataNascimentoResponsavel &&
        data.passo4CpfResponsavel &&
        data.passo4Telefone1Responsaval &&
        data.passo4TipoTelefone1Responsavel &&
        isEmailValid &&
        isCpfDifferent
      );
    });
  }, [data, setValidate]);

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h6" gutterBottom>
        Dados do Responsável
      </Typography>

      <Grid container spacing={2}>
        {/* Nome do Responsável */}
        <Grid item md={6} xs={12}>
          <TextField
            label="Responsável"
            fullWidth
            required
            value={data?.passo4NomeResponsavel || ""}
            onChange={(e) => onChange("passo4NomeResponsavel", e.target.value)}
          />
        </Grid>

        {/* Grau de Parentesco */}
        <Grid item md={6} xs={12}>
          <Autocomplete
            options={grauParentescoOptions}
            getOptionLabel={(option) => option.label}
            fullWidth
            renderInput={(params) => (
              <TextField
                {...params}
                label="Grau de Parentesco"
                variant="outlined"
                required
              />
            )}
            value={
              grauParentescoOptions.find(
                (option) => option.id === data.passo4GrauParentescoResponsavel
              ) || null
            }
            onChange={(e, value) =>
              onChange(
                "passo4GrauParentescoResponsavel",
                value ? value.id : null
              )
            }
          />
        </Grid>

        {/* Data de Nascimento do Responsável */}
        <Grid item md={6} xs={12}>
        <LocalizationProvider
  dateAdapter={AdapterDayjs}
  adapterLocale="pt-br"
>
  <DatePicker
    label="Data de Nascimento"
    format="DD/MM/YYYY"
    required
    value={
      data?.passo4DataNascimentoResponsavel &&
      dayjs(data?.passo4DataNascimentoResponsavel, "DD/MM/YYYY", true).isValid()
        ? dayjs(data?.passo4DataNascimentoResponsavel, "DD/MM/YYYY", true)
        : null
    }
    onChange={(newValue) =>
      onChange(
        "passo4DataNascimentoResponsavel",
        newValue ? newValue.toISOString() : null
      )
    }
    renderInput={(params) => (
      <TextField {...params} fullWidth required />
    )}
  />
</LocalizationProvider>

        </Grid>

        {/* CPF do Responsável */}
        <Grid item md={6} xs={12}>
  <InputMask
    mask="999.999.999-99"
    value={data?.passo4CpfResponsavel || ""}
    onChange={(e) => onChange("passo4CpfResponsavel", e.target.value)}
  >
    {() => (
      <TextField
        label="CPF do Responsável"
        fullWidth
        required
        variant="outlined"
        error={
          data?.passo4CpfResponsavel &&
          (!isValidCPF(data?.passo4CpfResponsavel) || 
            (data?.passo1Curso !== 4 &&
              data?.passo4CpfResponsavel === data?.passo2CpfAluno))
        }
        helperText={
          data?.passo4CpfResponsavel &&
          !isValidCPF(data?.passo4CpfResponsavel)
            ? "CPF inválido"
            : data?.passo1Curso !== 4 &&
              data?.passo4CpfResponsavel === data?.passo2CpfAluno
            ? "CPF do aluno não pode ser igual ao do responsável"
            : ""
        }
      />
    )}
  </InputMask>
</Grid>


        {/* E-mail do Responsável */}
        <Grid item md={12} xs={12}>
          <TextField
            label="E-mail do Responsável"
            fullWidth
            value={data?.passo4EmailResponsavel || ""}
            onChange={(e) => onChange("passo4EmailResponsavel", e.target.value)}
            error={
              data.passo4EmailResponsavel &&
              !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(data.passo4EmailResponsavel)
            }
            helperText={
              data.passo4EmailResponsavel &&
              !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(data.passo4EmailResponsavel) &&
              "E-mail inválido"
            }
          />
        </Grid>

        {/* Telefone 1 */}
        <Grid item md={6} xs={12}>
          <InputMask
            mask="(99) 99999-9999"
            value={data?.passo4Telefone1Responsaval || ""}
            onChange={(e) =>
              onChange("passo4Telefone1Responsaval", e.target.value)
            }
          >
            {() => (
              <TextField
                label="Telefone"
                fullWidth
                required
                variant="outlined"
              />
            )}
          </InputMask>
        </Grid>

        {/* Tipo do Telefone 1 */}
        <Grid item md={6} xs={12}>
          <Autocomplete
            options={tipoTelefoneOptions}
            getOptionLabel={(option) => option.label}
            fullWidth
            renderInput={(params) => (
              <TextField
                {...params}
                label="Tipo do Telefone"
                variant="outlined"
                required
              />
            )}
            value={
              tipoTelefoneOptions.find(
                (option) => option.id === data.passo4TipoTelefone1Responsavel
              ) || null
            }
            onChange={(e, value) =>
              onChange(
                "passo4TipoTelefone1Responsavel",
                value ? value.id : null
              )
            }
          />
        </Grid>

        {/* Telefone 2 */}
        <Grid item md={6} xs={12}>
          <InputMask
            mask="(99) 99999-9999"
            value={data?.passo4Telefone2Responsavel || ""}
            onChange={(e) =>
              onChange("passo4Telefone2Responsavel", e.target.value)
            }
          >
            {() => (
              <TextField label="Telefone 2" fullWidth variant="outlined" />
            )}
          </InputMask>
        </Grid>

        {/* Tipo do Telefone 2 */}
        <Grid item md={6} xs={12}>
          <Autocomplete
            options={tipoTelefoneOptions}
            getOptionLabel={(option) => option.label}
            fullWidth
            renderInput={(params) => (
              <TextField {...params} label="Tipo do Telefone 2" variant="outlined" />
            )}
            value={
              tipoTelefoneOptions.find(
                (option) => option.id === data.passo4TipoTelefone2Responsavel
              ) || null
            }
            onChange={(e, value) =>
              onChange(
                "passo4TipoTelefone2Responsavel",
                value ? value.id : null
              )
            }
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Etapa4;
