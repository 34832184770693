import React, { useEffect, useState } from "react";
import {
  Container,
  Box,
  Typography,
  TextField,
  Button,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import PostAddIcon from "@mui/icons-material/PostAdd";
import SchoolIcon from "@mui/icons-material/School";

import { pesquisaPorCpfPreMatricula } from "../../../../../services/processos/preMatricula";
import { toast } from "react-toastify";
import urlsPreMatricula from "../../../../processos/preMatricula/urlsPreMatricula";
import { useNavigate } from "react-router-dom";

const cpfMask = (value) => {
  return value
    .replace(/\D/g, "")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d{1,2})$/, "$1-$2")
    .substr(0, 14);
};

const isValidCPF = (cpf) => {
  const cleanedCPF = cpf.replace(/\D/g, "");
  if (cleanedCPF.length !== 11) return false;
  let sum = 0;
  let remainder;

  for (let i = 1; i <= 9; i++)
    sum += parseInt(cleanedCPF.substring(i - 1, i)) * (11 - i);

  remainder = (sum * 10) % 11;
  if (remainder === 10 || remainder === 11) remainder = 0;

  if (remainder !== parseInt(cleanedCPF.substring(9, 10))) return false;

  sum = 0;
  for (let i = 1; i <= 10; i++)
    sum += parseInt(cleanedCPF.substring(i - 1, i)) * (12 - i);

  remainder = (sum * 10) % 11;
  if (remainder === 10 || remainder === 11) remainder = 0;

  return remainder === parseInt(cleanedCPF.substring(10, 11));
};

const PreMatriculaAraruama = () => {
  const navigate = useNavigate();
  const [cpf, setCpf] = useState("");
  const [isCpfValid, setIsCpfValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");

  const handleCpfChange = (e) => {
    const maskedCpf = cpfMask(e.target.value);
    setCpf(maskedCpf);
    setIsCpfValid(isValidCPF(maskedCpf));
  };

  const handleNaoSouAluno = () => {
    navigate(urlsPreMatricula.formularioPreenchimento, { state: { isAluno: false } }); // Update with the correct route and pass state
  };

  const handleSouAluno = () => {
    navigate(urlsPreMatricula.formularioPreenchimento, { state: { isAluno: true } }); // Update with the correct route and pass state
  };

  const handleSearch = async () => {
    if (!isCpfValid) return;

    setLoading(true);
    setResponseMessage(""); // Reset response message before a new search

    try {
      const response = await pesquisaPorCpfPreMatricula(cpf.replace(/\D/g, ""));
      if (response?.data) {
        navigate(urlsPreMatricula.visualizar + response.data.id);
      } else {
        toast.info("Inscrição não encontrada.");
      }
    } catch (error) {
      console.error("Erro ao pesquisar CPF:", error);
      setResponseMessage("Erro ao pesquisar. Tente novamente.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    localStorage.removeItem("formData"); // Clear form data after successful submission
    localStorage.removeItem("activeStep"); // Clear step tracking
  }, []);

  return (
    <Container
      maxWidth="sm"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        textAlign: "center",
        bgcolor: "#f9f9f9",
        padding: 4,
        borderRadius: 2,
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Box
        component="img"
        src="/assets/images/prefeituras/seduc.png"
        alt="Prefeitura Logo"
        sx={{ width: 120, height: "auto", marginBottom: 2 }}
      />
      <Typography
        variant="h6"
        fontWeight="bold"
        gutterBottom
        sx={{ textTransform: "uppercase", color: "#2c3e50" }}
      >
        Prefeitura Municipal de Araruama
      </Typography>
      <Typography
        variant="subtitle1"
        gutterBottom
        sx={{ textTransform: "uppercase", color: "#2c3e50" }}
      >
        Secretaria de Educação
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: 4,
          width: "100%",
          gap: 2,
        }}
      >
        <TextField
          variant="outlined"
          placeholder="Digite o CPF do aluno"
          fullWidth
          value={cpf}
          onChange={handleCpfChange}
          error={cpf.length === 14 && !isCpfValid}
          helperText={cpf.length === 14 && !isCpfValid ? "CPF inválido" : " "}
          InputProps={{
            inputMode: "numeric",
            maxLength: 14,
          }}
          sx={{
            backgroundColor: "#fff",
            borderRadius: 1,
          }}
        />
        <Button
          variant="contained"
          color="primary"
          fullWidth
          disabled={!isCpfValid || loading}
          startIcon={<SearchIcon />}
          onClick={handleSearch}
          sx={{
            textTransform: "none",
            fontWeight: "bold",
            padding: "10px",
          }}
        >
          {loading ? "Pesquisando..." : "Pesquisar"}
        </Button>
        <Box
          sx={{
            display: "flex",
            gap: 2,
            width: "100%",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            fullWidth
            startIcon={<PostAddIcon />}
            onClick={handleNaoSouAluno}
            sx={{
              textTransform: "none",
              fontWeight: "bold",
              padding: "10px",
            }}
          >
            Novos Alunos
          </Button>
          <Button
            variant="outlined"
            color="primary"
            fullWidth
            startIcon={<SchoolIcon />}
            onClick={handleSouAluno}
            sx={{
              textTransform: "none",
              fontWeight: "bold",
              padding: "10px",
            }}
          >
            Transferência Interna
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default PreMatriculaAraruama;
