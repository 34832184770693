import Breadcrumb from '../../../../../../components/breadcrumb'
import BasePreMatricula from '../../../basePreMatricula';
import Alert from '@mui/material/Alert';
import Table from '../../../../../../components/table';
import { useMemo, useState,useEffect } from 'react';
import { useQuery } from 'react-query';
import { getInscricoesRelatorio } from '../../../../../../services/processos/preMatricula';
import handleDownloadExcel from '../../../../../../utils/handleDownloadExcel';
import { toast } from 'react-toastify';
import Select from 'react-select';
import api from '../../../../../../services';


const RelatorioInscritosPorUnidade = () => {

  const [unidade, setUnidade] = useState(0)
  const [skip, setSkip] = useState(0);
  const [exportar, setExportar] = useState(0);
  const [status, setStatus] = useState(1);
  const [limit, setLimit] = useState(20);
  const [data, setData] = useState([]);
  const onMenuOpen = () => setIsOpen(true);
  const onMenuClose = () => setIsOpen(false);
  const [isOpen, setIsOpen] = useState(false)
  const [unidades, setUnidades] = useState([])
  const nomeArquivo = 'relatotio-pre-matricula-2025-inscritos-por-unidade-quantitativo.xlsx'

  const columns = useMemo(
    () => [
    { Header: 'Unidade', accessor: 'unidadeDescricao' },
    { Header: 'Ano Escolaridade', accessor: 'escolaridadeDescricao' },
    { Header: 'Turno', accessor: 'turnoDescricao' },
    { Header: 'Quantidade', accessor: 'quantidade' },
    ],
    []
)

async function fetchInscricoes(value){
    setUnidade(value)
    var response = await getInscricoesRelatorio(value, 0, 0, 0, 0, '', skip, limit, exportar, status)
    setData(response || [])
}

const exportarDados = async () => {
    
  if(data.length === 0 || data?.total === 0){
    return toast.warning('Não há dados para exportar.')
  }

  try {

  const relatorioData = await getInscricoesRelatorio(0, 0, 0, 0, 0, '', 0, data?.total, 1, status)

  if (relatorioData) {
    return handleDownloadExcel(relatorioData, nomeArquivo);
  } else {
      return toast.error('Falha ao obter o relatório');
      // Tratar falha na obtenção do relatório
  }
  } catch (error) {
    return toast.error('Erro ao buscar relatório');
    // Tratar erro na chamada da função
  }
 
  // return await handleDownloadExcel(relatorioData, titulo);
}

useEffect(() => {
  getUnitName()
}, [limit, skip])

const getUnitName = async () => {
  try {
    const res = await api.get(`unidade`)
    console.log(res)

    const unidadesResponse = res?.data?.data

    var dados = [];
    unidadesResponse.forEach(item => {
      dados.push({ value: item.id, label: item.nome })
    });

    setUnidades(dados);
    // setUnidades(res?.data?.data)
    // setValue('unidadeID', res.data.data.nome)
  } catch (err) {
    console.log({ err })
  }
}


return (
    <BasePreMatricula>
      <Breadcrumb
        title={'POR UNIDADE - QUANTITATIVO'}
        itens={['PRÉ-MATRÍCULA 2025', 'RELATÓRIOS', 'INSCRITOS', 'POR UNIDADE']}
        hasExcelButton 
        onClickDownload={() => exportarDados()}   
      />

<Alert variant="filled" severity="info" style={{marginBottom: 20}}>Resultado com base nas inscrições como primeira opção.</Alert>

<div className="row" style={{marginBottom: 20}}>
<div className="col-12 col-md-12">
          <div className="form-group">
            <label htmlFor="unidadeId" className="form-label">
              Unidades
            </label>
            <Select
              aria-labelledby="aria-label"
              inputId="aria-example-input"
              name="aria-live-color"
              onMenuOpen={onMenuOpen}
              onMenuClose={onMenuClose}
              options={unidades}
              value={unidades.find(x => x.value == unidade)}
              placeholder='SELECIONE'             
              onChange={async e => {
                fetchInscricoes(e.value)
                // await setEscolaridade(0)
                // await setTurno(0)
                // await setTurma(0)
                // refetch()
                // refetchEscolaridades()
              }
              }
            />
          </div>
        </div>
        </div>

<div className="row">
        <div className="col-12">
          <div className="card">
        <Table
              columns={columns}
              data={data.data ? data.data : []}
              hasPagination
              limit={limit}
              setLimit={setLimit}
              skip={skip}
              setSkip={setSkip}
              totalItems={data?.total}
            />
            </div>
            </div>
            </div>
      </BasePreMatricula>
)
}

export default RelatorioInscritosPorUnidade