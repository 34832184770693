export const grauParentescoOptions = [
    { id: 1, label: 'PAI' },
    { id: 2, label: 'MÃE' },
    { id: 3, label: 'IRMÃO' },
    { id: 4, label: 'IRMÃ' },
    { id: 5, label: 'AVÔ' },
    { id: 6, label: 'AVÓ' },
    { id: 7, label: 'TIO' },
    { id: 8, label: 'TIA' },
    { id: 9, label: 'PRIMO' },
    { id: 10, label: 'PRIMA' },
    { id: 11, label: 'OUTROS' }
  ];
  