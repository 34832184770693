export const escolaridadesOptions = {
    1: [
      { id: 12, label: 'CRECHE I (6 MESES A 11 MESES)' },
      { id: 13, label: 'CRECHE II (1 ANO A 1 ANO E 11 MESES)' },
      { id: 14, label: 'CRECHE III (2 ANOS A 2 ANOS E 11 MESES)' },
      { id: 15, label: 'CRECHE IV (3 ANOS A 3 ANOS E 11 MESES)' },
      { id: 24, label: 'PRÉ I (4 ANOS A 4 ANOS E 11 MESES)' },
      { id: 25, label: 'PRÉ II (5 ANOS A 5 ANOS E 11 MESES)' },
    ],
    2: [
      { id: 1, label: '1º ANO' },
      { id: 2, label: '2º ANO' },
      { id: 3, label: '3º ANO' },
      { id: 4, label: '4º ANO' },
      { id: 5, label: '5º ANO' },
    ],
    3: [
      { id: 6, label: '6º ANO' },
      { id: 8, label: '7º ANO' },
      { id: 10, label: '8º ANO' },
      { id: 11, label: '9º ANO' },
    ],
    4: [
      { id: 30, label: 'FASE I' },
      { id: 16, label: 'FASE II' },
      { id: 17, label: 'FASE III' },
      { id: 18, label: 'FASE IV' },
      { id: 19, label: 'FASE IX' },
      { id: 20, label: 'FASE V' },
      { id: 21, label: 'FASE VI' },
      { id: 22, label: 'FASE VII' },
      { id: 23, label: 'FASE VIII' },
    ],
  };