export const courseOptions = [
    {
      id: 1,
      label: 'EDUCAÇÃO INFANTIL',
    },
    {
      id: 2,
      label: 'ENSINO FUNDAMENTAL I',
    },
    {
      id: 3,
      label: 'ENSINO FUNDAMENTAL II',
    },
    {
      id: 4,
      label: 'EDUCAÇÃO DE JOVENS E ADULTOS - EJA',
    },
  ];