import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  TextField,
  Typography,
  Grid,
} from "@mui/material";
import InputMask from "react-input-mask"; // Import InputMask
import { LocationApi } from "../../../../../services/external/cep";

const Etapa5 = ({ data, onChange, setValidate }) => {
  const locationApi = useRef(new LocationApi());
  const [cepError, setCepError] = useState(false);
  const [loadingCep, setLoadingCep] = useState(false);

  useEffect(() => {
    // Define the validation function for this step
    setValidate(() => () => {
      return (
        data.passo5Cep &&
        data.passo5Logradouro &&
        data.passo5Numero &&
        data.passo5Bairro &&
        data.passo5Cidade &&
        data.passo5Estado
      );
    });
  }, [data, setValidate]);

  const handleCepChange = async (cep) => {
    onChange("passo5Cep", cep);
    if (cep.replace(/\D/g, "").length === 8) {
      try {
        setLoadingCep(true);
        const response = await locationApi.current.searchByCep(cep);
        const { cidade, uf, bairro, logradouro } = response.data.data;

        onChange("passo5Cidade", cidade || "");
        onChange("passo5Estado", uf || "");
        onChange("passo5Bairro", bairro || "");
        onChange("passo5Logradouro", logradouro || "");
        setCepError(false);
      } catch (error) {
        console.error("Failed to fetch address by CEP:", error);
        setCepError(true);
      } finally {
        setLoadingCep(false);
      }
    }
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h6" gutterBottom>
        Informações e Endereço
      </Typography>

      <Grid container spacing={2}>
        {/* CEP */}
        <Grid item md={12} xs={12}>
          <InputMask
            mask="99999-999" // Define CEP mask
            value={data?.passo5Cep || ""}
            onChange={(e) => handleCepChange(e.target.value)}
          >
            {() => (
              <TextField
                label="CEP"
                fullWidth
                required
                error={cepError}
                helperText={cepError ? "CEP inválido ou não encontrado" : ""}
              />
            )}
          </InputMask>
        </Grid>

        {/* Logradouro */}
        <Grid item md={12} xs={12}>
          <TextField
            label="Endereço"
            fullWidth
            required
            value={data?.passo5Logradouro || ""}
            onChange={(e) => onChange("passo5Logradouro", e.target.value)}
            disabled={loadingCep}
          />
        </Grid>

        {/* Número */}
        <Grid item md={6} xs={12}>
          <TextField
            label="Número"
            fullWidth
            required
            value={data?.passo5Numero || ""}
            onChange={(e) => onChange("passo5Numero", e.target.value)}
          />
        </Grid>

        {/* Complemento */}
        <Grid item md={6} xs={12}>
          <TextField
            label="Complemento"
            fullWidth
            value={data?.passo5Complemento || ""}
            onChange={(e) => onChange("passo5Complemento", e.target.value)}
          />
        </Grid>

        {/* Bairro */}
        <Grid item md={6} xs={12}>
          <TextField
            label="Bairro"
            fullWidth
            required
            value={data?.passo5Bairro || ""}
            onChange={(e) => onChange("passo5Bairro", e.target.value)}
            disabled={loadingCep}
          />
        </Grid>

        {/* Cidade */}
        <Grid item md={6} xs={12}>
          <TextField
            label="Cidade"
            fullWidth
            required
            value={data?.passo5Cidade || ""}
            onChange={(e) => onChange("passo5Cidade", e.target.value)}
            disabled={loadingCep}
          />
        </Grid>

        {/* Estado */}
        <Grid item md={12} xs={12}>
          <TextField
            label="Estado"
            fullWidth
            required
            value={data?.passo5Estado || ""}
            onChange={(e) => onChange("passo5Estado", e.target.value)}
            disabled={loadingCep}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Etapa5;
