import React, {useEffect} from "react";
import {
  Autocomplete,
  Box,
  TextField,
  Typography,
  List,
  ListItem,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  FormControl,
  Checkbox,
  FormControlLabel
} from "@mui/material";
import { courseOptions } from "../data/coursesOptions";
import { distritoOptions } from "../data/distritosOptions";

const Etapa1 = ({data, onChange, setValidate}) => {
    useEffect(() => {
        // Define the validation function for this step
        setValidate(() => () => {
          return data.passo1Curso !== "" && data.passo1TermosPortaria;
        });
      }, [data, setValidate]);
  return (
    <Box sx={{ padding: 2 }}>
      {/* Welcome Message */}
      <Typography variant="h6" gutterBottom>
        Bem vindo(a) ao sistema eletrônico de pré-matrícula da rede municipal de ensino Araruama para ano letivo 2025.
      </Typography>

      {/* Autocomplete for Course Selection */}
      <Autocomplete
        options={courseOptions}
        getOptionLabel={(option) => option.label}
        fullWidth
        renderInput={(params) => (
          <TextField {...params} label="Escolha o curso" variant="outlined" margin="normal" />
        )}
        defaultValue={courseOptions.find((option) => option.id === data.passo1Curso)}
        onChange={(e, value) => onChange("passo1Curso", value ? value.id : null)}
      />

      {/* Informational Text */}
      <Box sx={{ marginTop: 3 }}>
        <Typography variant="body1" gutterBottom>
          Para que seu cadastro seja um sucesso fique atento para as seguintes informações:
        </Typography>
        <List>
          <ListItem>
            <ListItemText>
              - <strong>Atenção:</strong> ao realizar um novo cadastro, o anterior será excluído automaticamente.
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>- Preencha o formulário com atenção.</ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              - Forneça dados de contato ativos, pois serão importantes para comunicação em caso de convocação.
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              - Lista de doenças que se enquadram como PcD:
              <List>
                <ListItem>
                  <ListItemText>I – Baixa visão;</ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText>II – Cegueira;</ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText>III – Deficiência auditiva;</ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText>IV – Deficiência Física;</ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText>V – Deficiência Intelectual;</ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText>VI – Surdez;</ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText>VII – Surdocegueira;</ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText>VIII – Deficiência Múltipla;</ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText>
                    IX – Transtorno Global de Desenvolvimento-Autismo Infantil; Síndrome de
                    Asperger; Síndrome de Rett; Transtorno Desintegrativo da Infância;
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText>X – Altas habilidades/superdotação.</ListItemText>
                </ListItem>
              </List>
            </ListItemText>
          </ListItem>
        </List>
      </Box>

      {/* Distrito Options as Table */}
      <Box sx={{ marginTop: 0 }}>
        {/* <Typography variant="h6" gutterBottom>
          Lista de Distritos e Escolas:
        </Typography> */}
        <Table>
          <TableHead>
            <TableRow>
              <TableCell><strong>Distrito</strong></TableCell>
              <TableCell><strong>Nome</strong></TableCell>
              <TableCell><strong>Endereço</strong></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {distritoOptions.map((option, index) => (
              <TableRow key={index}>
                <TableCell>{option.distrito}</TableCell>
                <TableCell>{option.nome}</TableCell>
                <TableCell>{option.endereco}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        {/* Terms Checkbox */}
        <FormControlLabel
  control={
    <Checkbox
      checked={data.passo1TermosPortaria}
      onChange={(e) => onChange("passo1TermosPortaria", e.target.checked)}
    />
  }
  label={
    <span>
      Concordo com os termos da portaria. Leia a portaria completa{" "}
      <a
        href="/assets/files/prefeituras/EDITAL PRÉ-MATRICULA 2025.pdf"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "underline", color: "#007bff" }}
      >
        clicando aqui.
      </a>
    </span>
  }
  style={{ marginTop: 10 }}
/>

      </Box>
    </Box>
  );
};

export default Etapa1;
