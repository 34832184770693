import Breadcrumb from '../../../../../../components/breadcrumb'
import BasePreMatricula from '../../../basePreMatricula';


const RelatorioInscritosPorUnidadeNominal = () => {


return (
    <BasePreMatricula>
      <Breadcrumb
        title={'POR UNIDADE - NOMINAL'}
        itens={['PRÉ-MATRÍCULA 2025', 'RELATÓRIOS', 'INSCRITOS', 'POR UNIDADE NOMINAL']}
        hasExcelButton 
      />
      </BasePreMatricula>
)
}

export default RelatorioInscritosPorUnidadeNominal