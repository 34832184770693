import React, { useEffect, useState } from "react";
import { Box, Grid, Typography, Card, CardContent } from "@mui/material";
import { courseOptions } from "../data/coursesOptions";
import { escolaridadesOptions } from "../data/escolaridadesOptions";
import { getUnidades } from "../../../../../services/unidades";
import { turnosOptions } from "../data/turnosOptions";
import { carteiraDeNascimento } from "../data/carteiraDeNascimento";
import { grauParentescoOptions } from "../data/grauParentescoOptions";
import { tipoTelefoneOptions } from "../data/tipoTelefoneOptions";

const Etapa7 = ({ data, aviso = true }) => {
  const [unidadesOptions, setUnidadesOptions] = useState([]);

  // Fetch Unidades data
  useEffect(() => {
    getUnidades()
      .then((response) => {
        setUnidadesOptions(response || []);
      })
      .catch((error) => {
        console.error("Error fetching unidades:", error);
        setUnidadesOptions([]);
      });
  }, []);

  console.log(unidadesOptions)
  // Ensure `data.passo1Curso` exists and is valid
  const cursoText =
    courseOptions.find((option) => option.id === data?.passo1Curso)?.label ||
    "--";

  // Handle escolaridade options safely
  const filteredEscolaridades =
    escolaridadesOptions[data?.passo1Curso] || []; // Default to an empty array if undefined
  const anoText =
    filteredEscolaridades.find(
      (option) => option.id === data?.passo2Escolaridade
    )?.label || "--";

  // Handle turnos and unidades safely
  const turno1Text =
  turnosOptions.find((option) => option.id === data?.passo6TurnoEscolha1)
      ?.label || "--";
  const turno2Text =
  turnosOptions.find((option) => option.id === data?.passo6TurnoEscolha2)
      ?.label || "--";

  const unidade1Text =
    unidadesOptions.find((option) => option.id === data?.passo6UnidadeEscolha1)
      ?.nome || "--";
  const unidade2Text =
    unidadesOptions.find((option) => option.id === data?.passo6UnidadeEscolha2)
      ?.nome || "--";
  const unidadeIrmaoText =
    unidadesOptions.find((option) => option.id === data?.passo6UnidadeRedeIrmao)
      ?.nome || "--";

const modeloCarteiraNascimento = carteiraDeNascimento.find((option) => option.id === data?.passo3ModeloCertidaoNascimento)?.label || "--";
const grauParentesco = grauParentescoOptions.find((option) => option.id === data?.passo4GrauParentescoResponsavel)?.label || "--";
const responsavelTipoTelefone1 = tipoTelefoneOptions.find((option) => option.id === data?.passo4TipoTelefone1Responsavel)?.label || "--";
const responsavelTipoTelefone2 = tipoTelefoneOptions.find((option) => option.id === data?.passo4TipoTelefone2Responsavel)?.label || "--";

const rows = [
    { label: "CURSO", value: cursoText },
    { label: "ANO", value: anoText },
    { label: "NECESSIDADE ESPECIAL", value: data?.passo2Deficiente ? "SIM" : "NÃO" },
    {
      label: "DATA DE NASCIMENTO",
      value: data?.passo3DataNascimento &&
          !isNaN(Date.parse(data?.passo3DataNascimento.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$2/$1/$3')))
          ? new Date(data?.passo3DataNascimento.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$2/$1/$3')).toLocaleDateString("pt-BR")
          : "--",
  },
    { label: "CARTEIRINHA DO SUS", value: data?.passo3CarteirinhaSus || "--" },
    { label: "MODELO CERTIDÃO DE NASCIMENTO", value: modeloCarteiraNascimento || "--" },
    { label: "CPF DO RESPONSÁVEL", value: data?.passo4CpfResponsavel || "--" },
    { label: "GRAU DE PARENTESCO", value: grauParentesco },
    { label: "NOME DO RESPONSÁVEL", value: data?.passo4NomeResponsavel || "--" },
    {
      label: "DATA DE NASCIMENTO DO RESPONSÁVEL",
      value: data?.passo4DataNascimentoResponsavel
          ? new Date(data?.passo4DataNascimentoResponsavel).toLocaleDateString("pt-BR")
          : "--",
  },
  {
    label: "DATA DE NASCIMENTO DO RESPONSÁVEL",
    value: data?.passo4DataNascimentoResponsavel &&
        !isNaN(Date.parse(data?.passo4DataNascimentoResponsavel.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$2/$1/$3')))
        ? new Date(data?.passo4DataNascimentoResponsavel.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$2/$1/$3')).toLocaleDateString("pt-BR")
        : "--",
},
    { label: "TELEFONE 1 DO RESPONSÁVEL", value: data?.passo4Telefone1Responsaval || "--" },
    { label: "TIPO DE TELEFONE 1 DO RESPONSÁVEL", value: responsavelTipoTelefone1 },
    { label: "TELEFONE 2 DO RESPONSÁVEL", value: data?.passo4Telefone2Responsaval || "--" },
    { label: "TIPO DE TELEFONE 2 DO RESPONSÁVEL", value: responsavelTipoTelefone2 },
    { label: "E-MAIL DO RESPONSÁVEL", value: data?.passo4EmailResponsavel || "--" },
    { label: "CEP", value: data?.passo5Cep || "--" },
    { label: "ENDEREÇO", value: data?.passo5Logradouro || "--" },
    { label: "NÚMERO", value: data?.passo5Numero || "--" },
    { label: "COMPLEMENTO", value: data?.passo5Complemento || "--" },
    { label: "BAIRRO", value: data?.passo5Bairro || "--" },
    { label: "CIDADE", value: data?.passo5Cidade || "--" },
    { label: "ESTADO", value: data?.passo5Estado || "--" },
    { label: "TURNO/1ª OPÇÃO", value: turno1Text },
    { label: "UNIDADE/1ª OPÇÃO", value: unidade1Text },
    { label: "TURNO/2ª OPÇÃO", value: turno2Text },
    { label: "UNIDADE/2ª OPÇÃO", value: unidade2Text },
];

if (data?.passo6TemIrmaoNaRede) {
    rows.push(
        { label: "CONCORRE A MESMA UNIDADE DO IRMÃO", value: "SIM" },
        { label: "NOME DO IRMÃO", value: data?.passo6NomeDoIrmao || "--" },
        {
            label: "DATA DE NASCIMENTO DO IRMÃO",
            value: data?.passo6DataNascimentoIrmao
                ? new Date(data?.passo6DataNascimentoIrmao).toLocaleDateString("pt-BR")
                : "--",
        },
        { label: "UNIDADE DO IRMÃO", value: unidadeIrmaoText }
    );
}

  return (
    <Box sx={{ padding: 2 }}>
      {/* Red Informational Message */}
      {/* Student Name and CPF */}
      {aviso && (
        <>
         <Typography
            variant="body1"
            sx={{ color: "red", marginBottom: 2 }}
            >
            As informações abaixo são apenas para visualização, sendo necessário
            clicar no botão <b>ENVIAR</b> ao final dessa página.
            </Typography>
      <Typography variant="h4" gutterBottom>
        {data?.passo3NomeCompleto || "--"}
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        {data?.passo2CpfAluno || "--"}
      </Typography>
      </>
       )}

      {/* Table Inside Card */}
      <Card sx={{ marginTop: 2 }}>
        <CardContent>
          <Grid container spacing={0}>
            {rows.map((row, index) => (
              <React.Fragment key={index}>
                <Grid
                  item
                  xs={6}
                  sx={{
                    backgroundColor: index % 2 === 0 ? "#f5f5f5" : "#ffffff",
                    padding: 1,
                  }}
                >
                  <Typography variant="body1" fontWeight="bold">
                    {row.label}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{
                    backgroundColor: index % 2 === 0 ? "#f5f5f5" : "#ffffff",
                    padding: 1,
                  }}
                >
                  <Typography variant="body1">{row.value}</Typography>
                </Grid>
              </React.Fragment>
            ))}
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};

export default Etapa7;
