import React, { useCallback, useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import FormInput from "../../../../../../components/formInput";
import { getEstados, getCidades } from "../../../../../../services/endereco";
import { getNacionalidades } from "../../../../../../services/servidores";
import { toast } from "react-toastify";
import { useQuery, useMutation } from "react-query";
import { useForm } from "react-hook-form";
import {
  listarMunicipios,
  listarPolos,
} from "../../../../../../services/processos/remanejamento";
import { postInscricaoServidor } from "../../../../../../services/processos/remanejamento";
import api from "../../../../../../services";
import urlsServidores from "../../urlsServidores";
import InputMask from "react-input-mask";
import { isValidCPF } from "../../../../../../utils/isValidCpf";
import { LocationApi } from "../../../../../../services/external/cep";

export default function ProcessoSeletivoCivico() {
  const navigate = useNavigate();

  const [stateSelected, setStateSelected] = useState();
  const [validateName, setValidateName] = useState(false);
  const [cargoDiploma, setCargoDiploma] = useState(0);
  const [quantidadeDoutorados, setQuantidadeDoutorados] = useState(0);
  const [quantidadeMestrados, setQuantidadeMestrados] = useState(0);
  const [quantidadePosGraduacao, setQuantidadePosGraduacao] = useState(0);
  const [quantidadeCertificados, setQuantidadeCertificados] = useState(0);

  const [stopFunction, setStopFunction] = useState(false);
  const [exibeAnexosDocumento, setExibeAnexoDocumentos] = useState(0);
  const [exibeDisciplina, setExibeDisciplina] = useState(0);
  const [exibeCnh, setExibeCnh] = useState(0);
  const [exibeComprovaExperiencia, setExibeComprovaExperiencia] = useState(0);

  const allCargos = [
    { value: 0, label: "", escolaridades: [1, 2, 3, 4, 5, 6, 7, 8, 9] },
    {
      value: 3,
      label: "MERENDEIRA",
      escolaridades: [1, 2, 3, 4, 5, 6, 7, 8, 9],
    },
    {
      value: 4,
      label: "MONITOR DE TRANSPORTE ESCOLAR",
      escolaridades: [1, 2, 3, 4, 5, 6, 7, 8, 9],
    },
    {
      value: 5,
      label: "MOTORISTA D",
      escolaridades: [1, 2, 3, 4, 5, 6, 7, 8, 9],
    },
    {
      value: 12,
      label: "SECRETÁRIO ESCOLAR",
      escolaridades: [4, 5, 6, 7, 8, 9],
    },
    { value: 14, label: "SUPERVISOR EDUCACIONAL", escolaridades: [6, 7, 8, 9] },
  ];

  const [cargos, setCargos] = useState([
    { value: 0, label: "", escolaridades: [1, 2, 3, 4, 5, 6, 7, 8, 9] },
    {
      value: 3,
      label: "MERENDEIRA",
      escolaridades: [1, 2, 3, 4, 5, 6, 7, 8, 9],
    },
    {
      value: 4,
      label: "MONITOR DE TRANSPORTE ESCOLAR",
      escolaridades: [1, 2, 3, 4, 5, 6, 7, 8, 9],
    },
    {
      value: 5,
      label: "MOTORISTA D",
      escolaridades: [1, 2, 3, 4, 5, 6, 7, 8, 9],
    },
    {
      value: 12,
      label: "SECRETÁRIO ESCOLAR",
      escolaridades: [4, 5, 6, 7, 8, 9],
    },
    { value: 14, label: "SUPERVISOR EDUCACIONAL", escolaridades: [6, 7, 8, 9] },
  ]);

  function checkCargo(cargo) {
    setExibeDisciplina(0);
    setExibeAnexoDocumentos(0);
    setExibeCnh(0);
    setExibeComprovaExperiencia(0);

    if (
      cargo == 1 ||
      cargo == 2 ||
      cargo == 3 ||
      cargo == 4 ||
      cargo == 5 ||
      cargo == 6 ||
      cargo == 7 ||
      cargo == 12 ||
      cargo == 13
    ) {
      setExibeComprovaExperiencia(1);
    }

    if (
      cargo == 6 ||
      cargo == 8 ||
      cargo == 9 ||
      cargo == 10 ||
      cargo == 11 ||
      cargo == 14
    ) {
      setExibeAnexoDocumentos(1);
    }

    if (cargo == 10) {
      setExibeDisciplina(1);
    }
    //MOTORISTA EXIBE CNH
    if (cargo == 5) {
      setExibeCnh(1);
    }
  }

  const { data: estadosData } = useQuery("getEstados", () => getEstados(), {
    enabled: true,
    retry: true,
  });

  const { data: nacionalidadesData } = useQuery(
    "getNacionalidades",
    () => getNacionalidades(),
    {
      enabled: true,
      retry: true,
    }
  );

  const { data: cidadesData, refetch: cidadesRefetch } = useQuery(
    "getCidades",
    () => getCidades(stateSelected),
    {
      enabled: false,
      retry: true,
    }
  );

  const { mutate: mutatePostForm } = useMutation(postInscricaoServidor, {
    onSuccess: (data) => {
      if (data == undefined || data == false) {
        toast.error(
          "Ocorreu um erro no envio, favor tentar novamente mais tarde."
        );
      }
      if (data != false && data != undefined) {
        toast.success("Inscrição enviada com sucesso.");
        navigate(urlsServidores.visualizar + data.data.id);
      }
    },
    onError: (data) => {
      toast.error(
        "Ocorreu um erro no envio, favor tentar novamente mais tarde."
      );
    },
  });

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { isValid, errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      nome: "",
      nacionalidade: 0,
      endereco: "",
      numero: "",
      complemento: "",
      bairro: "",
      estado: 1,
      cidade: "",
      cep: "",
      telefoneResidencial: "",
      telefoneRecado: "",
      telefoneCelular: "",
      email: "",
      dataNascimento: "",
      sexo: 0,
      estadoCivil: "",
      numeroRg: "",
      orgaoExpedicaoRg: "",
      estadoExpedicaoRg: "",
      dataExpedicaoRg: "",
      cpf: "",
      deficiente: false,
      deficiencia: 0,
      anexoLaudoMedico: "",
      nomeDoPai: "",
      nomeDaMae: "",
      nivelDeEscolaridade: 0,
      anexoComprovanteEscolaridade: "",
      cargo: 0,
      anexoDoutorado1: "",
      anexoMestrado1: "",
      anexoMestrado2: "",
      anexoPosGraduacao1: "",
      anexoPosGraduacao2: "",
      anexoPosGraduacao3: "",
      anexoCertificados1: "",
      anexoCertificados2: "",
      anexoCertificados3: "",
      anexoCertificados4: "",
      anexoCertificados5: "",
      disciplina: 0,
      quantidadeMesesExperiencia: "",
      anexoComprovanteExperiencia: "",
      anexoRg: "",
      anexoCpf: "",
      anexoCnh: "",
    },
  });

  function removeEmpty(obj) {
    for (const key in obj) {
      if (obj[key] === null) {
        delete obj[key];
      }
    }
    return obj;
  }

  async function sendFile(file, arquivo) {
    let size = file?.size / 1000000;

    if (file?.size / 1000000 > 5) {
      return toast.error(
        "Erro no envio do arquivo, arquivo muito grande, máximo permitido 5MB."
      );
    }

    if (file?.type != "application/pdf") {
      return toast.error("O anexo deve ser no formato PDF.");
    }

    const formData = new FormData();
    formData.append("arquivo", file);

    try {
      const sendFile = await api.post("/arquivo/processo-seletivo", formData);

      return sendFile.data.data.id;
    } catch (err) {
      return toast.error(
        "Erro no envio do arquivo " + arquivo + ". Tente novamente mais tarde."
      );
    }
  }

  const { current: locationApi } = useRef(new LocationApi());

  const handleAutoCompleteForm = useCallback(
    async (cep) => {
      if (cep.length < 8) return;
      try {
        const data = await locationApi.searchByCep(cep);
        const { cidade, uf, bairro, logradouro } = data.data.data;

        if (data.status === 400) {
          toast.warn(data.data.message);
        }

        setValue("bairro", bairro);
        setValue("cidade", cidade.toUpperCase());
        setValue("estado", uf);
        setValue("endereco", logradouro);
      } catch (error) {
        toast.error(
          "Não foi possível carregar o endereço, cep não encontrado na base de dados do correio."
        );
      }
    },
    [setValue]
  );

  useEffect(() => {
    const cep = watch("cep");
    if (cep) {
      handleAutoCompleteForm(cep.replace(/\D/g, ""));
    }
  }, [watch("cep"), handleAutoCompleteForm]);

  useEffect(() => {
    debugger; // This will pause execution for debugging
    const selectedEscolaridade = watch("nivelDeEscolaridade");
    if (selectedEscolaridade) {
      const filtered = allCargos.filter(
        (cargo) => cargo.escolaridades.includes(parseInt(selectedEscolaridade)) // Fix: Access `escolaridades` of each `cargo`
      );
      setCargos(filtered);
    }
  }, [watch("nivelDeEscolaridade")]);

  const onSubmit = async (data) => {
    let anexoComprovanteExperienciaId = "";
    let anexoComprovanteEscolaridadeId = "";
    let anexoRgId = "";
    let anexoCpfId = "";
    let anexoCnhId = "";
    let anexoLaudoMedicoId = "";
    let anexoDoutoradoId1 = "";
    let anexoMestradoId1 = "";
    let anexoMestradoId2 = "";
    let anexoPosGraduacaoId1 = "";
    let anexoPosGraduacaoId2 = "";
    let anexoPosGraduacaoId3 = "";
    let anexoCertificadosId1 = "";
    let anexoCertificadosId2 = "";
    let anexoCertificadosId3 = "";
    let anexoCertificadosId4 = "";
    let anexoCertificadosId5 = "";

    if (watch("nome") === "") {
      return toast.error("O campo Nome é obrigatório.");
    }

    if (watch("nacionalidade") === 0) {
      return toast.error("O campo Nacionalidade é obrigatório.");
    }

    if (watch("endereco") === "") {
      return toast.error("O campo Endereço é obrigatório.");
    }

    if (watch("numero") === "") {
      return toast.error("O campo Número é obrigatório.");
    }

    if (watch("bairro") === "") {
      return toast.error("O campo Bairro é obrigatório.");
    }

    if (watch("estado") === "") {
      return toast.error("O campo Estado é obrigatório.");
    }

    if (watch("cidade") === "") {
      return toast.error("O campo Cidade é obrigatório.");
    }

    if (watch("cep") === "") {
      return toast.error("O campo CEP é obrigatório.");
    }

    if (watch("telefoneResidencial") === "") {
      return toast.error("O campo Telefone Residencial é obrigatório.");
    }

    if (watch("telefoneRecado") === "") {
      return toast.error("O campo Telefone Recado é obrigatório.");
    }

    if (watch("telefoneCelular") === "") {
      return toast.error("O campo Telefone Celular é obrigatório.");
    }

    if (watch("email") === "") {
      return toast.error("O campo Email é obrigatório.");
    }

    if (watch("dataNascimento") === "") {
      return toast.error("O campo Data de Nascimento é obrigatório.");
    }

    if (watch("sexo") === 0) {
      return toast.error("O campo Sexo é obrigatório.");
    }

    if (watch("estadoCivil") === "" || watch("estadoCivil") === 0) {
      return toast.error("O campo Estado Civil é obrigatório.");
    }

    if (watch("numeroRg") === "") {
      return toast.error("O campo Número do RG é obrigatório.");
    }

    if (watch("orgaoExpedicaoRg") === "") {
      return toast.error("O campo Órgão de Expedição do RG é obrigatório.");
    }

    if (watch("estadoExpedicaoRg") === "") {
      return toast.error("O campo Estado de Expedição do RG é obrigatório.");
    }

    if (watch("dataExpedicaoRg") === "") {
      return toast.error("O campo Data de Expedição do RG é obrigatório.");
    }

    if (watch("cpf") === "") {
      return toast.error("O campo CPF é obrigatório.");
    }
    if (!isValidCPF(watch("cpf"))) {
      return toast.error("CPF inválido.");
    }

    if (watch("deficiente") === 0) {
      return toast.error("O campo PcD é obrigatório.");
    }

    if (watch("nomeDoPai") === "") {
      return toast.error("O campo Nome do Pai é obrigatório.");
    }

    if (watch("nomeDaMae") === "") {
      return toast.error("O campo Nome da Mãe é obrigatório.");
    }

    if (
      watch("nivelDeEscolaridade") === "" ||
      watch("nivelDeEscolaridade") === 0
    ) {
      return toast.error("O campo Nível de Escolaridade é obrigatório.");
    }

    if (watch("dataExpedicaoRg") === "") {
      return toast.error("O campo Data de Expedição do RG é obrigatório.");
    }

    if (watch("cargo") === 0) {
      return toast.error("O campo Cargo é obrigatório.");
    }

    if (watch("anexoRg") === "") {
      return toast.error("O campo Anexo RG é obrigatório.");
    }

    if (watch("telefoneCelular") === "") {
      return toast.error("O campo Telefone Celular é obrigatório.");
    }

    if (watch("telefoneResidencial") === "") {
      return toast.error("O campo Telefone Residencial é obrigatório.");
    }

    if (watch("telefoneRecado") === "") {
      return toast.error("O campo Telefone Recado é obrigatório.");
    } else {
      const { anexoRg } = data;
      anexoRgId = await sendFile(anexoRg[0], "rg");
      if (anexoRgId.length <= 10) {
        return;
      }
    }

    if (watch("anexoCpf") === "") {
      return toast.error("O campo Anexo CPF é obrigatório.");
    } else {
      const { anexoCpf } = data;
      anexoCpfId = await sendFile(anexoCpf[0], "cpf");
      if (anexoCpfId.length <= 10) {
        return;
      }
    }

    debugger;
    if (watch("deficiente") == true || watch("deficiente") == 1) {
      if (watch("deficiencia") == 0 || watch("deficiencia") == "") {
        return toast.error("O campo Deficiência é obrigatório.");
      }

      if (watch("anexoLaudoMedico") == "") {
        return toast.error("O campo Anexo Laudo Médico é obrigatório.");
      } else {
        const { anexoLaudoMedico } = data;
        anexoLaudoMedicoId = await sendFile(
          anexoLaudoMedico[0],
          "laudo médico."
        );
        if (anexoLaudoMedicoId.length <= 10) {
          return;
        }
      }
    }

    if (quantidadeDoutorados >= 1) {
      if (watch("anexoDoutorado1") == "") {
        return toast.error("O campo Doutorado 1 é obrigatório.");
      }
      if (watch("anexoDoutorado1") != "") {
        const { anexoDoutorado1 } = data;
        anexoDoutoradoId1 = await sendFile(anexoDoutorado1[0], "doutorado 1.");
        if (anexoDoutoradoId1.length <= 10) {
          return;
        }
      }
    }

    if (quantidadeMestrados >= 1) {
      if (watch("anexoMestrado1") == "") {
        return toast.error("O campo Mestrado 1 é obrigatório.");
      }
      if (watch("anexoMestrado2") == "") {
        return toast.error("O campo Mestrado 2 é obrigatório.");
      }
      if (watch("anexoMestrado1") != "") {
        const { anexoMestrado1 } = data;
        anexoMestradoId1 = await sendFile(anexoMestrado1[0], "mestrado 1.");
        if (anexoMestradoId1.length <= 10) {
          return;
        }
      }
      if (watch("anexoMestrado2") != "") {
        const { anexoMestrado2 } = data;
        anexoMestradoId2 = await sendFile(anexoMestrado2[0], "mestrado 2.");
        if (anexoMestradoId2.length <= 10) {
          return;
        }
      }
    }

    if (quantidadePosGraduacao >= 1) {
      if (watch("anexoPosGraduacao1") == "") {
        return toast.error("O campo Pós Graduação 1 é obrigatório.");
      }
      if (watch("anexoPosGraduacao2") == "") {
        return toast.error("O campo Pós Graduação 2 é obrigatório.");
      }
      if (watch("anexoPosGraduacao3") == "") {
        return toast.error("O campo Pós Graduação 3 é obrigatório.");
      }
      if (watch("anexoPosGraduacao1") != "") {
        const { anexoPosGraduacao1 } = data;
        anexoPosGraduacaoId1 = await sendFile(
          anexoPosGraduacao1[0],
          "pós graduação 1."
        );
        if (anexoPosGraduacaoId1.length <= 10) {
          return;
        }
      }
      if (watch("anexoPosGraduacao2") != "") {
        const { anexoPosGraduacao2 } = data;
        anexoPosGraduacaoId2 = await sendFile(
          anexoPosGraduacao2[0],
          "pós graduação 2."
        );
        if (anexoPosGraduacaoId2.length <= 10) {
          return;
        }
      }
      if (watch("anexoPosGraduacao3") != "") {
        const { anexoPosGraduacao3 } = data;
        anexoPosGraduacaoId3 = await sendFile(
          anexoPosGraduacao3[0],
          "pós graduação 3."
        );
        if (anexoPosGraduacaoId3.length <= 10) {
          return;
        }
      }
    }

    if (quantidadeCertificados >= 1) {
      if (watch("anexoCertificados1") == "") {
        return toast.error("O campo Certificados 1 é obrigatório.");
      }
      if (watch("anexoCertificados2") == "") {
        return toast.error("O campo Certificados 2 é obrigatório.");
      }
      if (watch("anexoCertificados3") == "") {
        return toast.error("O campo Certificados 3 é obrigatório.");
      }
      if (watch("anexoCertificados4") == "") {
        return toast.error("O campo Certificados 4 é obrigatório.");
      }
      if (watch("anexoCertificados5") == "") {
        return toast.error("O campo Certificados 5 é obrigatório.");
      }
      if (watch("anexoCertificados1") != "") {
        const { anexoCertificados1 } = data;
        anexoCertificadosId1 = await sendFile(
          anexoCertificados1[0],
          "certificado 1."
        );
        if (anexoCertificadosId1.length <= 10) {
          return;
        }
      }
      if (watch("anexoCertificados2") != "") {
        const { anexoCertificados2 } = data;
        anexoCertificadosId2 = await sendFile(
          anexoCertificados2[0],
          "certificado 2."
        );
        if (anexoCertificadosId2.length <= 10) {
          return;
        }
      }
      if (watch("anexoCertificados3") != "") {
        const { anexoCertificados3 } = data;
        anexoCertificadosId3 = await sendFile(
          anexoCertificados3[0],
          "certificado 3."
        );
        if (anexoCertificadosId3.length <= 10) {
          return;
        }
      }
      if (watch("anexoCertificados4") != "") {
        const { anexoCertificados4 } = data;
        anexoCertificadosId4 = await sendFile(
          anexoCertificados4[0],
          "certificado 4."
        );
        if (anexoCertificadosId4.length <= 10) {
          return;
        }
      }
      if (watch("anexoCertificados5") != "") {
        const { anexoCertificados5 } = data;
        anexoCertificadosId5 = await sendFile(
          anexoCertificados5[0],
          "certificado 5."
        );
        if (anexoCertificadosId5.length <= 10) {
          return;
        }
      }
    }

    if (exibeComprovaExperiencia == 1) {
      if (watch("quantidadeMesesExperiencia") == "") {
        return toast.error(
          "O campo Quantidade de Meses de Experiência é obrigatório."
        );
      }

      if (watch("anexoComprovanteExperiencia") == "") {
        return toast.error("O campo Comprovante de Experiência é obrigatório.");
      } else {
        const { anexoComprovanteExperiencia } = data;
        anexoComprovanteExperienciaId = await sendFile(
          anexoComprovanteExperiencia[0],
          "comprovante de experiência."
        );
        if (anexoComprovanteExperienciaId.length <= 10) {
          return;
        }
      }
    }

    if (exibeCnh == 1) {
      if (watch("anexoCnh") == "") {
        return toast.error("O campo Anexo CNH é obrigatório.");
      } else {
        const { anexoCnh } = data;
        anexoCnhId = await sendFile(anexoCnh[0], "cnh.");
        if (anexoCnhId.length <= 10) {
          return;
        }
      }
    }

    if (watch("anexoComprovanteEscolaridade") == "") {
      return toast.error(
        "Anexo de comprovante de escolaridade é obrigatórios."
      );
    } else {
      const { anexoComprovanteEscolaridade } = data;
      anexoComprovanteEscolaridadeId = await sendFile(
        anexoComprovanteEscolaridade[0],
        "comprovante de escolaridade."
      );
      if (anexoComprovanteEscolaridadeId.length <= 10) {
        return;
      }
    }

    const request = {
      ...data,
      anexoComprovanteEscolaridade: anexoComprovanteEscolaridadeId,
      anexoComprovanteExperiencia: anexoComprovanteExperienciaId,
      anexoRg: anexoRgId,
      anexoCpf: anexoCpfId,
      anexoCnh: anexoCnhId,
      anexoLaudoMedico: anexoLaudoMedicoId,
      anexoDoutorado1: anexoDoutoradoId1,
      anexoMestrado1: anexoMestradoId1,
      anexoMestrado2: anexoMestradoId2,
      anexoPosGraduacao1: anexoPosGraduacaoId1,
      anexoPosGraduacao2: anexoPosGraduacaoId2,
      anexoPosGraduacao3: anexoPosGraduacaoId3,
      anexoCertificados1: anexoCertificadosId1,
      anexoCertificados2: anexoCertificadosId2,
      anexoCertificados3: anexoCertificadosId3,
      anexoCertificados4: anexoCertificadosId4,
      anexoCertificados5: anexoCertificadosId5,
      deficiente: data?.deficiente == 1 ? true : false,
    };
    mutatePostForm(removeEmpty(request));
  };

  useEffect(() => {
    if (estadosData?.length > 0) {
      var estadoSelecionado = estadosData?.find(
        (estado) => estado.sigla === getValues("estado")
      );
      setStateSelected(estadoSelecionado?.id);
    }
  }, [watch("estado")]);

  useEffect(() => {
    if (
      stateSelected !== null &&
      stateSelected !== undefined &&
      stateSelected !== "SELECIONE"
    ) {
      cidadesRefetch();
    }
  }, [stateSelected]);

  return (
    <div
      className="tab-pane fade show active"
      id="listaEspera"
      role="tabpanel"
      aria-labelledby="listaEspera-tab"
    >
      <form id="form" onSubmit={handleSubmit(onSubmit)}>
        <div className="card-body">
          <div className="row">
            <div className="col-md-6">
              <FormInput
                register={register}
                errors={errors}
                atribute="nome"
                label="Nome"
              />
              {/* {!validateName && watch('nome').length == 0 &&   (
                <span className="text-danger">Campo obrigatório</span>
              )} */}
            </div>

            <div className="col-6">
              <div className="form-group">
                <label htmlFor="nacionalidade">
                  Nacionalidade
                  <span style={{ color: "red", marginLeft: "4px" }}>*</span>
                </label>
                <select
                  className="form-select"
                  id="nacionalidade"
                  {...register("nacionalidade")}
                >
                  <option></option>
                  {nacionalidadesData?.data?.map((estados) => (
                    <option key={estados.id} value={estados.descricao}>
                      {estados.descricao}
                    </option>
                  ))}
                </select>
                {/* {errors?.nacionalidade?.type === 'required' && (
                  <span className="text-danger">Campo obrigatório</span>
                )} */}
              </div>
            </div>
          </div>

          <div className="row" style={{ marginTop: "20px" }}>
            <div className="col-md-3">
              <FormInput
                register={register}
                errors={errors}
                atribute="cep"
                label="CEP"
                mask={"99999-999"}
              />
            </div>
            <div className="col-md-4">
              <FormInput
                register={register}
                errors={errors}
                atribute="endereco"
                label="Endereço"
              />
            </div>

            <div className="col-md-2">
              <FormInput
                register={register}
                errors={errors}
                atribute="numero"
                label="Número"
              />
            </div>

            <div className="col-md-3">
              <FormInput
                register={register}
                errors={errors}
                required={false}
                atribute="complemento"
                label="Complemento"
              />
            </div>
          </div>

          <div className="row" style={{ marginTop: "20px" }}>
            <div className="col-md-4">
              <FormInput
                register={register}
                errors={errors}
                atribute="bairro"
                label="Bairro"
              />
            </div>

            <div className="col-4">
              <div className="form-group">
                <label htmlFor="estado">
                  Estado
                  <span style={{ color: "red", marginLeft: "4px" }}>*</span>
                </label>
                <select
                  className="form-select"
                  id="estado"
                  {...register("estado")}
                >
                  <option></option>
                  {estadosData?.map((estados) => (
                    <option key={estados.id} value={estados.sigla}>
                      {estados.sigla}
                    </option>
                  ))}
                </select>
                {/* {errors?.estado?.type === 'required' && (
                  <span className="text-danger">Campo obrigatório</span>
                )} */}
              </div>
            </div>

            <div className="col-4">
              <div className="form-group">
                <label htmlFor="cidade">
                  Cidade
                  <span style={{ color: "red", marginLeft: "4px" }}>*</span>
                </label>
                <select
                  className="form-select"
                  id="cidade"
                  style={{ maxHeight: "100px", overflow: "auto" }}
                  {...register("cidade")}
                >
                  <option></option>
                  {cidadesData?.map((cidades) => (
                    <option key={cidades.id} value={cidades.nome}>
                      {cidades.nome}
                    </option>
                  ))}
                </select>
                {/* {errors?.cidade?.type === 'required' && (
                  <span className="text-danger">Campo obrigatório</span>
                )} */}
              </div>
            </div>
          </div>

          <div className="row" style={{ marginTop: "20px" }}>
            <div className="col-md-3">
              <div>
                <label>
                  Tel Residencial
                  <span style={{ color: "red", marginLeft: "4px" }}>*</span>
                </label>
                <InputMask
                  mask="(99) 9999-9999"
                  alwaysShowMask={false}
                  required
                  {...register("telefoneResidencial")}
                  className={`form-control ${
                    errors.telefoneResidencial ? "is-invalid" : ""
                  }`}
                />
                {errors.telefoneResidencial && (
                  <div className="invalid-feedback">
                    {errors.telefoneResidencial.message}
                  </div>
                )}
              </div>
            </div>

            <div className="col-md-3">
              <div>
                <label>
                  Tel Recado/Nome
                  <span style={{ color: "red", marginLeft: "4px" }}>*</span>
                </label>
                <InputMask
                  mask="(99) 99999-9999"
                  alwaysShowMask={false}
                  required
                  {...register("telefoneRecado")}
                  className={`form-control ${
                    errors.telefoneRecado ? "is-invalid" : ""
                  }`}
                />
                {errors.telefoneRecado && (
                  <div className="invalid-feedback">
                    {errors.telefoneRecado.message}
                  </div>
                )}
              </div>
            </div>

            <div className="col-md-3">
              <div>
                <label>
                  Celular
                  <span style={{ color: "red", marginLeft: "4px" }}>*</span>
                </label>
                <InputMask
                  mask="(99) 99999-9999"
                  alwaysShowMask={false}
                  required
                  {...register("telefoneCelular")}
                  className={`form-control ${
                    errors.telefoneCelular ? "is-invalid" : ""
                  }`}
                />
                {errors.telefoneCelular && (
                  <div className="invalid-feedback">
                    {errors.telefoneCelular.message}
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-3">
              <FormInput
                register={register}
                errors={errors}
                type="email"
                atribute="email"
                label="Endereço Eletrônico (e-mail)"
              />
            </div>
          </div>

          <div className="row" style={{ marginTop: "20px" }}>
            <div className="col-md-4">
              <FormInput
                register={register}
                errors={errors}
                type="date"
                atribute="dataNascimento"
                label="Data Nascimento"
              />
            </div>

            <div className="col-md-4">
              <div className="form-group">
                <label htmlFor="sexo">
                  Sexo<span style={{ color: "red", marginLeft: "4px" }}>*</span>
                </label>
                <select
                  className="form-select"
                  id="sexo"
                  {...register("sexo", { valueAsNumber: true })}
                >
                  <option value=""></option>
                  <option value={1}>FEMININO</option>
                  <option value={2}>MASCULINO</option>
                </select>
                {/* {errors?.sexo?.type === 'required' && (
                  <span className="text-danger">Campo obrigatório</span>
                )} */}
              </div>
            </div>

            <div className="col-md-4">
              <FormInput
                register={register}
                errors={errors}
                atribute="estadoCivil"
                label="Estado Civil"
              />
            </div>
          </div>

          <div className="row" style={{ marginTop: "20px" }}>
            <div className="col-md-2">
              <FormInput
                register={register}
                errors={errors}
                atribute="numeroRg"
                label="RG Nº"
              />
            </div>

            <div className="col-md-2">
              <FormInput
                register={register}
                errors={errors}
                atribute="orgaoExpedicaoRg"
                label="Órg. Exp"
              />
            </div>

            <div className="col-3">
              <div className="form-group">
                <label htmlFor="estadoExpedicaoRg">
                  Estado
                  <span style={{ color: "red", marginLeft: "4px" }}>*</span>
                </label>
                <select
                  className="form-select"
                  id="estadoExpedicaoRg"
                  {...register("estadoExpedicaoRg")}
                >
                  <option></option>
                  {estadosData?.map((estados) => (
                    <option key={estados.id} value={estados.sigla}>
                      {estados.sigla}
                    </option>
                  ))}
                </select>
                {/* {errors?.estado?.type === 'required' && (
                  <span className="text-danger">Campo obrigatório</span>
                )} */}
              </div>
            </div>

            <div className="col-md-2">
              <FormInput
                register={register}
                errors={errors}
                type="date"
                atribute="dataExpedicaoRg"
                label="Expedição"
              />
            </div>

            <div className="col-md-3">
              <div>
                <label>
                  CPF<span style={{ color: "red", marginLeft: "4px" }}>*</span>
                </label>
                <InputMask
                  mask="999.999.999-99"
                  {...register("cpf", {
                    required: "O CPF é obrigatório.",
                  })}
                  className={`form-control ${errors.cpf ? "is-invalid" : ""}`}
                />
                {errors.cpf && (
                  <div className="invalid-feedback">{errors.cpf.message}</div>
                )}
              </div>
            </div>
          </div>

          <div className="row" style={{ marginTop: 20 }}>
            <div className="col-md-6">
              <FormInput
                register={register}
                errors={errors}
                type="file"
                atribute="anexoRg"
                label="Cópia RG"
                readOnly={false}
              />
            </div>
            <div className="col-md-6">
              <FormInput
                register={register}
                errors={errors}
                type="file"
                atribute="anexoCpf"
                label="Cópia CPF"
                readOnly={false}
              />
            </div>
          </div>

          <div className="row" style={{ marginTop: 30 }}>
            <div
              className={watch("deficiente") !== 1 ? "col-md-12" : "col-md-4"}
            >
              <div className="form-group">
                <label htmlFor="deficiente">
                  PcD?<span style={{ color: "red", marginLeft: "4px" }}>*</span>
                </label>
                <select
                  className="form-select"
                  id="deficiente"
                  {...register("deficiente", { valueAsNumber: true })}
                >
                  <option value={0}></option>
                  <option value={1}>SIM</option>
                  <option value={2}>NÃO</option>
                </select>
                {/* {errors?.deficiente?.type === 'required' && (
                  <span className="text-danger">Campo obrigatório</span>
                )} */}
              </div>
            </div>

            {watch("deficiente") === 1 && (
              <>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="deficiencia">
                      Deficiência
                      <span style={{ color: "red", marginLeft: "4px" }}>*</span>
                    </label>
                    <select
                      className="form-select"
                      id="deficiencia"
                      {...register("deficiencia", { valueAsNumber: true })}
                    >
                      <option value={0}></option>
                      <option value={1}>DEFICIÊNCIA FÍSICA</option>
                      <option value={2}>
                        DEFICIÊNCIA INTELECTUAL / SÍNDROME DE DOWN
                      </option>
                      <option value={5}>
                        SUPERDOTAÇÃO / ALTAS HABILIDADES
                      </option>
                      <option value={3}>
                        TDA/ TDAH/ DISLEXIA/ DISORTOGRAFIA
                      </option>
                      <option value={4}>TRANSTORNO DO ESPECTRO AUTISTA</option>
                    </select>
                    {errors?.deficiencia?.type === "required" && (
                      <span className="text-danger">Campo obrigatório</span>
                    )}
                  </div>
                </div>

                <div className="col-md-4">
                  <FormInput
                    register={register}
                    errors={errors}
                    type="file"
                    atribute="anexoLaudoMedico"
                    label="Anexar Laudo Médico"
                    readOnly={false}
                  />
                  {/* {!anexoValid &&   (
                <span className="text-danger">Campo obrigatório</span>
              )} */}
                </div>
              </>
            )}
          </div>

          <div className="row" style={{ marginTop: "20px" }}>
            <div className="col-md-6">
              <FormInput
                register={register}
                errors={errors}
                atribute="nomeDoPai"
                label="Nome do pai"
              />
            </div>

            <div className="col-md-6">
              <FormInput
                register={register}
                errors={errors}
                atribute="nomeDaMae"
                label="Nome da mãe"
              />
            </div>
          </div>

          <div className="row" style={{ marginTop: 20 }}>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="nivelDeEscolaridade">
                  Nível de escolaridade
                  <span style={{ color: "red", marginLeft: "4px" }}>*</span>
                </label>
                <select
                  className="form-select"
                  id="nivelDeEscolaridade"
                  {...register("nivelDeEscolaridade", {
                    valueAsNumber: true,
                    required: true,
                  })}
                >
                  <option value={0}></option>
                  <option value={1}>FUNDAMENTAL INCOMPLETO</option>
                  <option value={2}>FUNDAMENTAL COMPLETO</option>
                  <option value={3}>MÉDIO INCOMPLETO</option>
                  <option value={4}>MÉDIO COMPLETO</option>
                  <option value={5}>SUPERIOR INCOMPLETO</option>
                  <option value={6}>SUPERIOR COMPLETO</option>
                  <option value={7}>PÓS-GRADUAÇÃO</option>
                  <option value={8}>MESTRADO</option>
                  <option value={9}>DOUTORADO</option>
                </select>
                {/* {errors?.nivelDeEscolaridade?.type === 'required' && (
                  <span className="text-danger">Campo obrigatório</span>
                )} */}
              </div>
            </div>

            <div className="col-md-3">
              <FormInput
                register={register}
                errors={errors}
                type="file"
                atribute="anexoComprovanteEscolaridade"
                label="Comprovante Escolaridade"
                readOnly={false}
              />
            </div>

            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="cargo">
                  Cargo
                  <span style={{ color: "red", marginLeft: "4px" }}>*</span>
                </label>
                <select
                  className="form-select"
                  id="cargo"
                  {...register("cargo", { valueAsNumber: true })}
                  onChange={async (e) => {
                    checkCargo(e.target.value);
                  }}
                >
                  {cargos?.map((cargo) => (
                    <option key={cargo.value} value={cargo.value}>
                      {cargo.label}
                    </option>
                  ))}
                </select>
                {/* Uncomment this line if required validation is added */}
                {/* {errors?.cargo?.type === 'required' && (
        <span className="text-danger">Campo obrigatório</span>
      )} */}
              </div>
            </div>
          </div>

          <div className="row" style={{ marginTop: 20 }}>
            {exibeAnexosDocumento == 1 && (
              <>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="quantidadeDoutorados">
                      Quantidade Doutorados
                      <span style={{ color: "red", marginLeft: "4px" }}>*</span>
                    </label>
                    <select
                      className="form-select"
                      id="quantidadeDoutorados"
                      {...register("quantidadeDoutorados", {
                        valueAsNumber: true,
                      })}
                      defaultValue={quantidadeDoutorados}
                      onChange={async (e) => {
                        setQuantidadeDoutorados(e.target.value);
                      }}
                    >
                      <option value={0}>0</option>
                      <option value={1}>1</option>
                    </select>
                    {/* {errors?.disciplina?.type === 'required' && (
                  <span className="text-danger">Campo obrigatório</span>
                )} */}
                  </div>
                </div>

                {quantidadeDoutorados >= 1 && (
                  <div className="col-md-6">
                    <FormInput
                      register={register}
                      errors={errors}
                      type="file"
                      atribute="anexoDoutorado1"
                      label="Doutorado 1"
                      required={true}
                      readOnly={false}
                    />
                  </div>
                )}

                <div className="row" style={{ marginTop: 20 }}>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="quantidadeMestrados">
                        Quantidade Mestrados
                        <span style={{ color: "red", marginLeft: "4px" }}>
                          *
                        </span>
                      </label>
                      <select
                        className="form-select"
                        id="quantidadeMestrados"
                        {...register("quantidadeMestrados", {
                          valueAsNumber: true,
                        })}
                        defaultValue={quantidadeMestrados}
                        onChange={async (e) => {
                          setQuantidadeMestrados(e.target.value);
                        }}
                      >
                        <option value={0}>0</option>
                        <option value={1}>1</option>
                        <option value={2}>2</option>
                      </select>
                      {/* {errors?.disciplina?.type === 'required' && (
                  <span className="text-danger">Campo obrigatório</span>
                )} */}
                    </div>
                  </div>

                  {quantidadeMestrados >= 1 && (
                    <div className="col-md-3">
                      <FormInput
                        register={register}
                        errors={errors}
                        type="file"
                        atribute="anexoMestrado1"
                        label="Mestrado 1"
                        required={true}
                        readOnly={false}
                      />
                    </div>
                  )}
                  {quantidadeMestrados >= 2 && (
                    <div className="col-md-3">
                      <FormInput
                        register={register}
                        errors={errors}
                        type="file"
                        atribute="anexoMestrado2"
                        label="Mestrado 2"
                        required={true}
                        readOnly={false}
                      />
                    </div>
                  )}
                </div>

                <div className="row" style={{ marginTop: 20 }}>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="quantidadePosGraduacao">
                        Quantidade Pós Graduação
                        <span style={{ color: "red", marginLeft: "4px" }}>
                          *
                        </span>
                      </label>
                      <select
                        className="form-select"
                        id="quantidadePosGraduacao"
                        {...register("quantidadePosGraduacao", {
                          valueAsNumber: true,
                        })}
                        defaultValue={quantidadePosGraduacao}
                        onChange={async (e) => {
                          setQuantidadePosGraduacao(e.target.value);
                        }}
                      >
                        <option value={0}>0</option>
                        <option value={1}>1</option>
                        <option value={2}>2</option>
                        <option value={3}>3</option>
                      </select>
                      {/* {errors?.disciplina?.type === 'required' && (
                  <span className="text-danger">Campo obrigatório</span>
                )} */}
                    </div>
                  </div>

                  {quantidadePosGraduacao >= 1 && (
                    <div className="col-md-3">
                      <FormInput
                        register={register}
                        errors={errors}
                        type="file"
                        atribute="anexoPosGraduacao1"
                        label="Pós Graduação 1"
                        required={true}
                        readOnly={false}
                      />
                    </div>
                  )}
                  {quantidadePosGraduacao >= 2 && (
                    <div className="col-md-3">
                      <FormInput
                        register={register}
                        errors={errors}
                        type="file"
                        atribute="anexoPosGraduacao2"
                        label="Pós Graduação 2"
                        required={true}
                        readOnly={false}
                      />
                    </div>
                  )}

                  {quantidadePosGraduacao >= 3 && (
                    <div className="col-md-3">
                      <FormInput
                        register={register}
                        errors={errors}
                        type="file"
                        atribute="anexoPosGraduacao3"
                        label="Pós Graduação 3"
                        required={true}
                        readOnly={false}
                      />
                    </div>
                  )}
                </div>

                <div className="row" style={{ marginTop: 20 }}>
                  <div className="col-md-2">
                    <div className="form-group">
                      <label htmlFor="quantidadeCertificados">
                        Certificados e/ou Formação +40h
                        <span style={{ color: "red", marginLeft: "4px" }}>
                          *
                        </span>
                      </label>
                      <select
                        className="form-select"
                        id="quantidadeCertificados"
                        {...register("quantidadeCertificados", {
                          valueAsNumber: true,
                        })}
                        defaultValue={quantidadeCertificados}
                        onChange={async (e) => {
                          setQuantidadeCertificados(e.target.value);
                        }}
                      >
                        <option value={0}>0</option>
                        <option value={1}>1</option>
                        <option value={2}>2</option>
                        <option value={3}>3</option>
                        <option value={4}>4</option>
                        <option value={5}>5</option>
                      </select>
                      {/* {errors?.disciplina?.type === 'required' && (
                  <span className="text-danger">Campo obrigatório</span>
                )} */}
                    </div>
                  </div>

                  {quantidadeCertificados >= 1 && (
                    <div className="col-md-2">
                      <FormInput
                        register={register}
                        errors={errors}
                        type="file"
                        atribute="anexoCertificados1"
                        label="Certificados 1"
                        required={true}
                        readOnly={false}
                      />
                    </div>
                  )}
                  {quantidadeCertificados >= 2 && (
                    <div className="col-md-2">
                      <FormInput
                        register={register}
                        errors={errors}
                        type="file"
                        atribute="anexoCertificados2"
                        label="Certificados 2"
                        required={true}
                        readOnly={false}
                      />
                    </div>
                  )}

                  {quantidadeCertificados >= 3 && (
                    <div className="col-md-2">
                      <FormInput
                        register={register}
                        errors={errors}
                        type="file"
                        atribute="anexoCertificados3"
                        label="Certificados 3"
                        required={true}
                        readOnly={false}
                      />
                    </div>
                  )}
                  {quantidadeCertificados >= 4 && (
                    <div className="col-md-2">
                      <FormInput
                        register={register}
                        errors={errors}
                        type="file"
                        atribute="anexoCertificados4"
                        label="Certificados 4"
                        required={true}
                        readOnly={false}
                      />
                    </div>
                  )}
                  {quantidadeCertificados >= 5 && (
                    <div className="col-md-2">
                      <FormInput
                        register={register}
                        errors={errors}
                        type="file"
                        atribute="anexoCertificados5"
                        label="Certificados 5"
                        required={true}
                        readOnly={false}
                      />
                    </div>
                  )}
                </div>
              </>
            )}
            {exibeDisciplina == 1 && (
              <div className="row" style={{ marginTop: 20 }}>
                <div className="col-md-12">
                  <div className="form-group">
                    <label htmlFor="disciplina">
                      Disciplina
                      <span style={{ color: "red", marginLeft: "4px" }}>*</span>
                    </label>
                    <select
                      className="form-select"
                      id="disciplina"
                      {...register("disciplina", {
                        valueAsNumber: true,
                      })}
                    >
                      <option value={0}></option>
                      <option value={1}>LÍNGUA PORTUGUESA</option>
                      <option value={2}>CIÊNCIAS</option>
                      <option value={3}>HISTÓRIA</option>
                      <option value={4}>GEOGRAFIA</option>
                      <option value={5}>LÍNGUA INGLESA</option>
                      <option value={6}>ARTES</option>
                      <option value={7}>EDUCAÇÃO FÍSICA</option>
                      <option value={8}>MATEMÁTICA</option>
                      <option value={9}>EDUCAÇÃO ESPECIAL</option>
                    </select>
                    {/* {errors?.disciplina?.type === 'required' && (
                  <span className="text-danger">Campo obrigatório</span>
                )} */}
                  </div>
                </div>
              </div>
            )}
            {exibeComprovaExperiencia == 1 && (
              <>
                <div className="col-md-6">
                  <FormInput
                    register={register}
                    errors={errors}
                    asNumber={true}
                    atribute="quantidadeMesesExperiencia"
                    label="Quantidade de meses de experiêcia"
                    readOnly={false}
                  />
                </div>
                <div className="col-md-6">
                  <FormInput
                    register={register}
                    errors={errors}
                    type="file"
                    atribute="anexoComprovanteExperiencia"
                    label="Comprovante de experiência"
                    readOnly={false}
                  />
                </div>
              </>
            )}
            {exibeCnh == 1 && (
              <>
                <div className="row" style={{ marginTop: 20 }}>
                  <div className="col-md-12">
                    <FormInput
                      register={register}
                      errors={errors}
                      type="file"
                      atribute="anexoCnh"
                      label="Cópia CNH"
                      readOnly={false}
                    />
                  </div>
                </div>
              </>
            )}
          </div>

          <div className="row" style={{ marginTop: 30 }}>
            <hr />
            <div className="col-md-12">
              <label htmlFor="termoDeclaracao">
                {/* <h4>Termo de declaração e ciência:</h4> */}
                <p>
                  Realizei a leitura e concordo com os termos do{" "}
                  <a
                    href="https://educacaoararuama.rj.gov.br/wp-content/uploads/2024/12/Retificacao-Edital-023_2024.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    edital
                  </a>
                  .
                </p>
              </label>

              <div
                className="col-md-12"
                style={{ display: "flex", alignItems: "center" }}
              >
                <input
                  type="checkbox"
                  id="termoDeclaracao"
                  name="termoDeclaracao"
                  style={{ marginRight: "12px", width: "20px", height: "20px" }}
                  {...register("termoDeclaracao", {
                    required: true,
                    valueAsNumber: true,
                  })}
                />
                <label for="termoDeclaracao">
                  Dou meu consentimento para o tratamento dos meus dados,
                  seguindo os termos da LGPD.
                </label>
              </div>
            </div>
            {errors?.termoDeclaracao?.type === "required" && (
              <span className="text-danger" style={{ marginTop: "8px" }}>
                Concorde com os termos para enviar o formulário.
              </span>
            )}
          </div>

          {/* <div className="row" style={{ marginTop: 30 }}>
            <div className='col-md-12'>
              <label><h4>Observação importante!</h4>Senhor(a) responsável, informamos que caso o aluno possua um cadastro anterior, o mesmo será cancelado e subtituído pelo novo.</label>
            </div>
          </div> */}

          {/* <div className="row" style={{ marginTop: 30 }}>
          <hr />
          <div className='col-md-12'>
            <label><h6>*Uma cópia das suas respostas será enviada para o endereço de e-mail fornecido.</h6></label>
          </div>
        </div> */}
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "50px",
          }}
        >
          <button
            type="button"
            className="btn btn-warning"
            onClick={() => navigate(urlsServidores.formulario)}
          >
            Voltar
          </button>
          <button type="submit" className="btn btn-success">
            Enviar
          </button>
        </div>
      </form>
    </div>
  );
}
