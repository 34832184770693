export const turnosOptions = [
    {
      id: 1,
      label: 'INTEGRAL',
    },
    {
      id: 2,
      label: 'MANHÃ',
    },
    {
      id: 3,
      label: 'NOITE',
    },
    {
      id: 4,
      label: 'TARDE',
    },
  ];