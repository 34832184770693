import Breadcrumb from "../../../../../components/breadcrumb";
import BasePreMatricula from "../../basePreMatricula";
import { useState } from "react";
import {
  pesquisaNome,
  getInscricoesPreMatriculaRelatorio,
  pesquisaPorFiltro,
} from "../../../../../services/processos/preMatricula";
import Table from "../../../../../components/table";
import { useMemo } from "react";
import handleDownloadExcel from "../../../../../utils/handleDownloadExcel";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const PreMatriculaPesquisarNome = () => {
  const [tipoFiltro, setTipoFiltro] = useState("id");
  const [pesquisa, setPesquisa] = useState("");
  const [data, setData] = useState([]);
  const [nomeAluno, setNomeAluno] = useState("");

  const navigate = useNavigate();

  async function handleSearch() {
    const response = await pesquisaPorFiltro(tipoFiltro, pesquisa);
    setData(response);
  }

  const exportarDados = async () => {
    if (data.length === 0 || data?.total === 0) {
      return toast.warning("Não há dados para exportar.");
    }

    try {
      const relatorioData = await getInscricoesPreMatriculaRelatorio(
        0,
        0,
        0,
        0,
        0,
        0,
        data?.data[0].nome,
        0,
        0
      );

      if (relatorioData) {
        return handleDownloadExcel(relatorioData, "relatorio-pre-matricula");
      } else {
        return toast.error("Falha ao obter o relatório");
      }
    } catch (error) {
      return toast.error("Erro ao buscar relatório");
    }
  };

  function abrirInscricao(id) {
    navigate(`/pre-matricula/formulario/visualizacao/${id}`)
  }

  const columns = useMemo(() => [
    { Header: "#", accessor: "id" },
    { Header: "Unidade", accessor: "unidade" },
    { Header: "Opção", accessor: "opcao" },
    { Header: "Estudante", accessor: "nome" },
    // { Header: 'CPF', accessor: 'cpf' },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ row }) => (
        <span className={`badge bg-dark`}>{row.original.status}</span>
      ),
    },
    {
      Header: "",
      accessor: "actions",
      Cell: ({ row }) => (
        <>
          <button
            onClick={
              () => {
                abrirInscricao(row.original.id);
              }
              // navigate(urls.processosSeletivosVInscricao + row.original.id)
            }
            className="btn btn-sm btn-primary"
          >
            <i className="material-icons md-edit_note"></i>
          </button>
        </>
      ),
    },
  ]);

  return (
    <BasePreMatricula>
      <Breadcrumb
        title={"PESQUISAR"}
        itens={["PRÉ-MATRICULA 2025", "INSCRIÇÕES", "PESQUISAR"]}
        hasExcelButton
        onClickDownload={() => exportarDados()}
      />
      {/* <form id="form" onSubmit={onsubmit()}> */}
      <div className="card-body">
        {/* <div className='row'>
                    <div className='col-8'>
                      <label htmlFor="nome" className="form-label">Nome</label>
                      <input type="text" className="form-control" id="nome"
                      />
                      
                    </div>
                    <div className='col-4'>
                        <button></button>
                    </div>
                  </div> */}

        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            marginTop: 10,
            marginBottom: 20,
          }}
        >
          <div className="col-5">
            <label htmlFor="descricao" className="form-label">
              TIPO DE FILTRO
            </label>
            {
              <select
                className="form-select"
                id="tipoFiltro"
                value={tipoFiltro}
                onChange={async (e) => {
                  setTipoFiltro(e.target.value);
                }}
              >
                <option value="id">ID</option>
                <option value="nomeAluno" selected>
                  Nome Aluno
                </option>
                <option value="cpfAluno">CPF Aluno</option>
                <option value="filiacao">Filiação</option>
                <option value="nomeResponsavel">Nome Responsável</option>
              </select>
            }
          </div>

          <div
            className="form-group col-md-6"
            style={{ marginLeft: "10px", marginRight: "10px" }}
          >
            <label htmlFor="descricao" className="form-label">
              PESQUISA
            </label>
            <input
              type="text"
              className="form-control"
              placeholder="Informe a pesquisa"
              value={pesquisa}
              onChange={(e) => setPesquisa(e.target.value)}
            />
          </div>

          <div className="col-1">
            <button
              className="btn btn-sm btn-primary"
              style={{ marginTop: "37px" }}
              onClick={() => handleSearch()}
            >
              <i className="material-icons md-search"></i> Buscar
            </button>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="card">
              <Table
                columns={columns}
                data={data?.data ? data?.data : []}
                hiddenColluns={["cpf"]}
                //   hasPagination={true}
                //   skip={0}
                //   limit={20}
                //   totalItems={data?.data.length}
              />
            </div>
          </div>
        </div>
      </div>
      {/* </form> */}
    </BasePreMatricula>
  );
};

export default PreMatriculaPesquisarNome;
