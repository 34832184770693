import FormularioProcessoSeletivoServidorTab from './tab/index'


const FormularioProcessoSeletivoServidor = () => {

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      padding: '24px 48px',
    }}>
      <img src='/assets/images/prefeituras/seduc.png' width="200" height="150" style={{ display: 'flex', alignSelf: 'center' }} />
      <h6 style={{ margin: '15px', alignSelf: 'center' }}>PREFEITURA MUNICIPAL DE ARARUAMA</h6>
      <h4 style={{ alignSelf: 'center' }}>SECRETARIA DE EDUCAÇÃO</h4>
      <h4 style={{ margin: '15px', alignSelf: 'center' }}>PROCESSO SIMPLIFICADO PARA FORMAÇÃO DE CADASTRO DE RESERVA Nº 023/2024</h4>
      {/* <h2 style={{ margin: '15px', alignSelf: 'center' }}>SOMENTE PARA ALUNOS DAS UNIDADES DE ENSINO QUE NÃO ATENDEM O SEGMENTO NECESSÁRIO À MATRÍCULA 2025.</h2> */}
      <div className="row">
        <div className="mailbox-container">
          <div className="card">
            <div className="container-fluid">
              <div className="row">
                <div className="mailbox-open-content col-md-12 m-0 p-3">
                    <div className="tab-content" id="myTabContent">
                    <FormularioProcessoSeletivoServidorTab />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FormularioProcessoSeletivoServidor
