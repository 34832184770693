import { useMemo, useEffect, useState } from "react";
import Breadcrumb from "../../../../components/breadcrumb";
import Table from "../../../../components/table";
import BasePreMatricula from "../basePreMatricula";
import urlsPreMatricula from "../urlsPreMatricula";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Alert from "@mui/material/Alert";
import Select from "react-select";
import { Modal, Button, Dropdown } from "react-bootstrap";

import {
  getInscricoesPreMatricula,
  postStatusPreMatricula,
} from "../../../../services/processos/preMatricula2024";

import { getEscolaridadesUnidade } from "../../../../services/escolaridades";

import { getUnidades } from "../../../../services/unidades";
import { useQuery, useMutation } from "react-query";
import { getTurnosUnidadeEscolaridade } from "../../../../services/turnos";
import { FilterAltOutlined } from "@mui/icons-material";
import { Chip, Menu, Button as ButtonComponet } from "@mui/material";

const PreMatriculaInscricoes = () => {
  const navigate = useNavigate();

  let unidadeFiltro =
    localStorage.getItem("psMatriculas2024UnidadeFiltro") === null
      ? 0
      : localStorage.getItem("psMatriculas2024UnidadeFiltro");
  let escolaridadeFiltro =
    localStorage.getItem("psMatriculas2024EscolaridadeFiltro") === null
      ? 0
      : localStorage.getItem("psMatriculas2024EscolaridadeFiltro");
  let turnoFiltro =
    localStorage.getItem("psMatriculas2024TurnoFiltro") === null
      ? 0
      : localStorage.getItem("psMatriculas2024TurnoFiltro");
  let estudanteDaRedeFiltro =
    localStorage.getItem("psMatriculas2024EstudanteDaRedeFiltro") === null
      ? 2
      : localStorage.getItem("psMatriculas2024EstudanteDaRedeFiltro");
  let statusFiltro =
    localStorage.getItem("psMatriculas2024StatusFiltro") === null
      ? 1
      : localStorage.getItem("psMatriculas2024StatusFiltro");
  let deficienteFiltro =
    localStorage.getItem("psMatriculas2024DefecienteFiltro") === null
      ? 1
      : localStorage.getItem("psMatriculas2024DefecienteFiltro");
  let nomeFiltro =
    localStorage.getItem("psMatriculas2024NomeFiltroFiltro") === null
      ? ""
      : localStorage.getItem("psMatriculas2024NomeFiltroFiltro");

  const [data, setData] = useState([]);
  const [unidade, setUnidade] = useState(unidadeFiltro);
  const [unidades, setUnidades] = useState([]);
  const [escolaridade, setEscolaridade] = useState(escolaridadeFiltro);
  const [numero, setNumero] = useState(true);
  const [totalVagas, setTotalVagas] = useState(0);
  const [totalAlunos, setTotalAlunos] = useState(0);
  const [ocultaBotaoConvocar, setOcultaBotaoConvocar] = useState(false);
  const [estudanteDaRede, setEstudanteDaRede] = useState(estudanteDaRedeFiltro);
  const [status, setStatus] = useState(statusFiltro);
  const [deficiente, setDeficiente] = useState(deficienteFiltro);
  const [turno, setTurno] = useState(turnoFiltro);
  const [nome, setNome] = useState(nomeFiltro);
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(20);

  const onMenuOpen = () => setIsOpen(true);
  const onMenuClose = () => setIsOpen(false);
  const [isOpen, setIsOpen] = useState(false);

  const { mutate } = useMutation(postStatusPreMatricula, {
    onSuccess: (message) => {
      toast.success(message);
      refetch();
    },
    onError: (error) => {
      if (error.response) {
        const { message } = error.response.data;
        toast.error(message);
      } else if (error.request) {
        toast.error("Erro ao tentar excluir, tente novamente mais tarde.");
      } else {
        toast.error(error.message);
      }
    },
  });

  const ActionsMenu = ({ row }) => {
    return (
      <Dropdown>
        <Dropdown.Toggle variant="secondary" id="dropdown-basic">
          Ações
        </Dropdown.Toggle>

        <Dropdown.Menu style={{ marginTop: 0 }}>
            <Dropdown.Item
              className="dropdown-item-primary d-flex"
              onClick={() => abrirInscricao(row.original.id)}
            >
              <i className="material-icons md-edit_note"></i> Visualizar
            </Dropdown.Item>
         
          {ocultaBotaoConvocar === false && (
            <Dropdown.Item
              className="dropdown-item-success d-flex"
              onClick={() => {
                atualizarStatus(
                  row.original.id,
                  row.original.passo2Deficiente,
                  row.original.passo3NomeCompleto,
                  row.original.opcaoPrincipal,
                  row.original.passo2CpfAluno
                );
              }}
              
            >
               <i className="material-icons md-add"></i> Atualizar
            </Dropdown.Item>
          )}
          
            <Dropdown.Item
            className="dropdown-item-secondary d-flex"
            onClick={() => {
              visualizarHistorico(
                row.original.id,
                row.original.passo2Deficiente,
                row.original.passo3NomeCompleto,
                row.original.opcaoPrincipal,
                row.original.passo2CpfAluno
              );
            }}
            
          >
             <i className="material-icons md-person"></i> Histórico
          </Dropdown.Item>
         
          
        </Dropdown.Menu>
      </Dropdown>
    );
  };


  const { isLoading: loadingUnidades, refetch: refetchUnidades } = useQuery(
    "getUnidades",
    () => getUnidades(),
    {
      enabled: true,
      retry: true,
      onSuccess: (data) => {
        var dados = [{ value: 0, label: "" }];
        data.forEach((item) => {
          dados.push({ value: item.id, label: item.nome });
        });

        setUnidades(dados);
      },
    }
  );

  const { isError, isLoading, refetch } = useQuery(
    "inscricoes",
    () =>
      getInscricoesPreMatricula(
        unidade,
        escolaridade,
        turno,
        estudanteDaRede,
        deficiente,
        status,
        nome,
        skip,
        limit
      ),
    {
      retry: 0,
      enabled: turno > 0,
      onSuccess: (data) => {
        console.log(data);
        setData(data);

        let totalVagas = data?.data?.vagas?.vagasDisponiveisTotal;
        let totalAlunos = data?.data?.vagas?.vagasMatriculadosTotal + data?.data?.vagas?.vagasConvocadosTotal;
        setTotalAlunos(totalAlunos);
        setTotalVagas(totalVagas);

        if (totalVagas <= totalAlunos) {
          setOcultaBotaoConvocar(true);
        } else {
          setOcultaBotaoConvocar(false);
        }
      },
    }
  );

  function abrirInscricao(id) {
    // localStorage.setItem("CPF", cpf);
    window.open(
      urlsPreMatricula.visualizar +
        id,
      "_blank"
    );
  }

  function atualizarStatus(id, pne, nome, opcao, cpf) {
    navigate(urlsPreMatricula.status + "/" + id, {
      state: {
        id: id,
        nome: nome,
        unidade: opcao,
        cpf: cpf,
      },
    });
  }

  function visualizarHistorico(id, pne, nome, opcao, cpf) {
    navigate(urlsPreMatricula.historico + "/" + id, {
      state: {
        id: id,
        nome: nome,
        unidade: opcao,
        cpf: cpf,
      },
    });
  }

  function statusDecription(value) {
    switch (value) {
      case 1:
        return "PRÉ-MATRÍCULA";
      case 2:
        return "CONVOCADO";
      case 3:
        return "MATRICULADO";
      case 4:
        return "NÃO COMPARECIDO";
      case 5:
        return "INDEFERIDO";
      case 6:
        return "CANCELADO";
      case 7:
        return "DESISTÊNCIA";
      case 8:
        return "MATRICULADO POR OUTRA OPÇÃO";
      case 9:
        return "CONVOCADO POR OUTRA OPÇÃO";
      case 10:
        return "MATRICULADO EM OUTRA OPÇÃO";
      case 0:
        return "SEM STATUS";
    }
  }

  function statusOpcao(opcao, statusOpcao1, statusOpcao2) {
    if (opcao == 1) {
      return statusDecription(statusOpcao1);
    } else {
      return statusDecription(statusOpcao2);
    }
  }

  const {
    data: escolaridades,
    isLoading: loadingEscolaridades,
    refetch: refetchEscolaridades,
  } = useQuery("getEscolaridade", () => getEscolaridadesUnidade(unidade, 2024), {
    retry: 0,
    enabled: unidade > 0,
  });

  const {
    data: turnos,
    isLoading: loadingTurnos,
    refetch: refetchTurnos,
  } = useQuery(
    "getTurmasUnidadeEscolaridadeTurno",
    () => getTurnosUnidadeEscolaridade(unidade, escolaridade, 2024),
    {
      retry: 0,
      enabled: unidade > 0 && escolaridade > 0,
    }
  );

  const columns = useMemo(
    () => [
      { Header: "#", accessor: "id" },
      { Header: "Opção", accessor: "opcaoPrincipal" },
      { Header: "Nome", accessor: "passo3NomeCompleto" },
      { Header: "CPF", accessor: "passo2CpfAluno" },
      {
        Header: "Nascimento",
        accessor: "passo3DataNascimento",
        Cell: ({ row }) =>
          row.original.passo3DataNascimento
            .split("T")[0]
            .split("-")
            .reverse()
            .join("/"),
      },
      {
        Header: "Última Atualização",
        accessor: "passo7DataCriacao",
        Cell: ({ row }) =>
          row.original.passo7DataCriacao
            .split("T")[0]
            .split("-")
            .reverse()
            .join("/") +
          " " +
          row.original.passo7DataCriacao.split("T")[1].split(".")[0],
      },
      {
        Header: "Mora Próximo da Unidade",
        accessor: "passo7ProximoUnidade",
        Cell: ({ row }) => (
          <span
            className={`badge bg-${
              row.original.passo7ProximoUnidade ? "success" : "danger"
            }`}
          >
            {row.original.passo7ProximoUnidade ? "SIM" : "NÃO"}
          </span>
        ),
      },
      {
        Header: "Irmão?",
        accessor: "passo6TemIrmaoNaRede",
        Cell: ({ row }) => (
          <span
            className={`badge bg-${
              row.original.passo6TemIrmaoNaRede ? "success" : "danger"
            }`}
          >
            {row.original.passo6TemIrmaoNaRede ? "SIM" : "NÃO"}
          </span>
        ),
      },
      {
        Header: "Necessidade Especial",
        accessor: "passo2Deficiente",
        Cell: ({ row }) => (
          <span
            className={`badge bg-${
              row.original.passo2Deficiente ? "success" : "danger"
            }`}
          >
            {row.original.passo2Deficiente ? "SIM" : "NÃO"}
          </span>
        ),
      },
      {
        Header: "Status",
        accessor: "passo7StatusUnidade1",
        Cell: ({ row }) => (
          <span className={`badge bg-dark`}>
            {statusOpcao(
              row.original.opcaoPrincipal,
              row.original.passo7StatusUnidade1,
              row.original.passo7StatusUnidade2
            )}
          </span>
        ),
      },
      {
        Header: "",
        accessor: "actions",
        Cell: ({ row }) => <ActionsMenu row={row} />,
      },
    ],
    [atualizarStatus, ocultaBotaoConvocar, statusOpcao]
  );

  useEffect(() => {
    console.log("ocultaBotaoConvocar:", ocultaBotaoConvocar); // Debugging log
    if (nome.length >= 3) {
      setNome(nome);
      refetch();
    } else {
      refetch();
    }
  }, [refetch, limit, skip, nome, ocultaBotaoConvocar]);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseB = () => {
    setAnchorEl(null);
  };

  const cleanFilters = async () => {
    await setUnidade(0);
    await setEscolaridade(0);
    await setTurno(0);
    await setEstudanteDaRede(0);
    await setDeficiente(0);
    await setStatus(0);
    localStorage.removeItem("filtroTurmasAnoLetivo");
    localStorage.removeItem("filtroTurmasEscolaridade");
    localStorage.removeItem("filtroTurmasTurno");
    localStorage.removeItem("psMatriculas2024DefecienteFiltro");
    localStorage.removeItem("psMatriculas2024EscolaridadeFiltro");
    localStorage.removeItem("psMatriculas2024EstudanteDaRedeFiltro");
    localStorage.removeItem("psMatriculas2024StatusFiltro");
    localStorage.removeItem("psMatriculas2024TurnoFiltro");
    localStorage.removeItem("psMatriculas2024UnidadeFiltro");
    await refetch();
  };

  const unidadeSelecionada = unidades?.find((x) => x.value == unidade)?.label;
  const escolaridadeSelecionada = escolaridades?.find(
    (x) => x.id == escolaridade
  )?.descricao;
  const turnoSelecionado = turnos?.find((x) => x.id == turno)?.descricao;
  const estudanteDaRedeSelecionado =
    estudanteDaRede == 1 ? "SIM" : estudanteDaRede == 2 ? "NÃO" : null;
  const deficienteSelecionado =
    deficiente == 1 ? "SIM" : deficiente == 2 ? "NÃO" : null;
  const statusSelecionado =
    status == 1
      ? "PRÉ-MATRÍCULA"
      : status == 2
      ? "CONVOCADO"
      : status == 3
      ? "MATRICULADO"
      : status == 4
      ? "NÃO COMPARECIDO"
      : status == 5
      ? "INDEFERIDO"
      : status == 6
      ? "CANCELADO"
      : status == 7
      ? "DESISTÊNCIA"
      : status == 8
      ? "MATRICULADO POR OUTRA OPÇÃO"
      : status == 9
      ? "CONVOCADO POR OUTRA OPÇÃO"
      : null;

  return (
    <BasePreMatricula>
      <Breadcrumb
        title={"Inscrições"}
        itens={["Pré-Matricula 2024", "Inscrições", "Lista Geral"]}
      />

      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          gap: "12px",
        }}
      >
        <ButtonComponet
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          startIcon={<FilterAltOutlined />}
          variant="contained"
          size="large"
          color="info"
        >
          Filtro
        </ButtonComponet>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleCloseB}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <div style={{ padding: "20px", width: "400px" }}>
            <div className="form-group">
              <label htmlFor="unidade" className="form-label">
                Unidade
              </label>
              <Select
                aria-labelledby="aria-label"
                inputId="aria-example-input"
                name="aria-live-color"
                onMenuOpen={onMenuOpen}
                onMenuClose={onMenuClose}
                options={unidades}
                value={unidades?.find((x) => x.value == unidade)}
                placeholder=""
                onChange={async (e) => {
                  await setUnidade(e.value);
                  await refetchEscolaridades();
                  setEscolaridade(0);
                  setTurno(0);
                  await refetch();
                  await localStorage.setItem(
                    "psMatriculas2024UnidadeFiltro",
                    e.value
                  );
                }}
              />
            </div>
            <div className="form-group">
              <label htmlFor="escolaridade" className="form-label">
                Escolaridades
              </label>
              <select
                className="form-select"
                id="escolaridade"
                value={escolaridade}
                onChange={async (e) => {
                  await setEscolaridade(e.target.value);
                  setTurno(0);
                  await refetchTurnos();
                  await localStorage.setItem(
                    "psMatriculas2024EscolaridadeFiltro",
                    e.target.value
                  );
                }}
              >
                <option value={0}></option>
                {escolaridades?.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.descricao}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="turno" className="form-label">
                Turno
              </label>
              <select
                className="form-select"
                id="turno"
                value={turno}
                onChange={async (e) => {
                  await setTurno(e.target.value);
                  await refetch();
                  await localStorage.setItem(
                    "psMatriculas2024TurnoFiltro",
                    e.target.value
                  );
                }}
              >
                {loadingTurnos ? (
                  <option value={0}></option>
                ) : (
                  <>
                    <option value={0}></option>
                    {turnos &&
                      turnos?.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.descricao}
                        </option>
                      ))}
                  </>
                )}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="estudanteDaRede" className="form-label">
                Estudante Da Rede?
              </label>
              <select
                className="form-select"
                id="estudanteDaRede"
                value={estudanteDaRede}
                onChange={async (e) => {
                  await setEstudanteDaRede(e.target.value);
                  await refetch();
                  await localStorage.setItem(
                    "psMatriculas2024EstudanteDaRedeFiltro",
                    e.target.value
                  );
                }}
              >
                <>
                  <option value={0}></option>
                  <option value={1}>SIM</option>
                  <option value={2}>NÃO</option>
                </>
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="deficiente" className="form-label">
                Necessidade Especial?
              </label>
              <select
                className="form-select"
                id="deficiente"
                value={deficiente}
                onChange={async (e) => {
                  await setDeficiente(e.target.value);
                  await refetch();
                  await localStorage.setItem(
                    "psMatriculas2024DefecienteFiltro",
                    e.target.value
                  );
                }}
              >
                <>
                  <option value={0}></option>
                  <option value={1}>SIM</option>
                  <option value={2}>NÃO</option>
                </>
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="status" className="form-label">
                Status
              </label>
              <select
                className="form-select"
                id="status"
                value={status}
                onChange={async (e) => {
                  await setStatus(e.target.value);
                  await refetch();
                  await localStorage.setItem(
                    "psMatriculas2024StatusFiltro",
                    e.target.value
                  );
                }}
              >
                <>
                  <option value={0}></option>
                  <option value={1}>PRÉ-MATRÍCULA</option>
                  <option value={2}>CONVOCADO</option>
                  <option value={3}>MATRICULADO</option>
                  <option value={4}>NÃO COMPARECIDO</option>
                  <option value={5}>INDEFERIDO</option>
                  <option value={6}>CANCELADO</option>
                  <option value={7}>DESISTÊNCIA</option>
                  <option value={8}>MATRICULADO POR OUTRA OPÇÃO</option>
                  <option value={9}>CONVOCADO POR OUTRA OPÇÃO</option>
                </>
              </select>
            </div>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Chip
                sx={{ marginTop: 2 }}
                color="error"
                label="Limpar filtros"
                onClick={cleanFilters}
              />
            </div>
          </div>
        </Menu>
        {unidadeSelecionada ? (
          <Chip
            color="info"
            label={`UNIDADE: ${unidadeSelecionada}`}
            variant="outlined"
            onDelete={async () => {
              await setUnidade(0);
              await setEscolaridade(0);
              await setTurno(0);
              localStorage.removeItem("psMatriculas2024UnidadeFiltro");
              refetch();
              refetchEscolaridades();
            }}
          />
        ) : null}
        {escolaridadeSelecionada ? (
          <Chip
            color="info"
            label={`ESCOLARIDADE: ${escolaridadeSelecionada}`}
            variant="outlined"
            onDelete={async () => {
              await setEscolaridade(0);
              await setTurno(0);
              await refetchTurnos(0);
              await localStorage.removeItem(
                "psMatriculas2024EscolaridadeFiltro"
              );
              await refetch();
            }}
          />
        ) : null}
        {turnoSelecionado ? (
          <Chip
            color="info"
            label={`TURNO: ${turnoSelecionado}`}
            variant="outlined"
            onDelete={async () => {
              await setTurno(0);
              await localStorage.removeItem("psMatriculas2024TurnoFiltro");
              await refetch();
            }}
          />
        ) : null}
        {estudanteDaRede > 0 ? (
          <Chip
            color="info"
            label={`ESTUDANTE DA REDE: ${estudanteDaRedeSelecionado}`}
            variant="outlined"
            onDelete={async () => {
              setEstudanteDaRede(0);
              await refetch();
            }}
          />
        ) : null}
        {deficiente > 0 ? (
          <Chip
            color="info"
            label={`NECESSIDADE ESPECIAL: ${deficienteSelecionado}`}
            variant="outlined"
            onDelete={async () => {
              setDeficiente(0);
              await refetch();
            }}
          />
        ) : null}
        {status > 0 ? (
          <Chip
            color="info"
            label={`STATUS: ${statusSelecionado}`}
            variant="outlined"
            onDelete={async () => {
              setStatus(0);
              await refetch();
            }}
          />
        ) : null}
      </div>

      <div className="col-12 col-md-12" style={{ marginTop: "10px" }}>
        <div className="form-group">
          <label htmlFor="regimeId" className="form-label">
            Pesquisar
          </label>
          <input
            type="text"
            className="form-control"
            placeholder="Pesquisar"
            value={nome}
            onChange={(e) => setNome(e.target.value)}
          />
        </div>
      </div>

      {turno > 0 && (
        <div className="row" style={{ marginBottom: 20 }}>
          <div className="col-4">
            <table
              style={{
                width: "100%",
                borderCollapse: "collapse",
                marginTop: "20px",
              }}
            >
              <thead>
                <tr>
                  <th
                    colSpan="3"
                    style={{
                      backgroundColor: "#2872ba",
                      textAlign: "center",
                      textTransform: "uppercase",
                      padding: "10px",
                    }}
                  >
                    Vagas Disponibilizadas
                  </th>
                </tr>
                <tr>
                  <th style={{ padding: "10px", textAlign: "center" }}>
                    Regular
                  </th>
                  <th style={{ padding: "10px", textAlign: "center" }}>PcD</th>
                  <th style={{ padding: "10px", textAlign: "center" }}>
                    Total
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ padding: "10px", textAlign: "center" }}>
                    {data.data?.vagas?.vagasDisponiveisRegulares || 0}
                  </td>
                  <td style={{ padding: "10px", textAlign: "center" }}>
                    {data.data?.vagas?.vagasDisponiveisPne || 0}
                  </td>
                  <td style={{ padding: "10px", textAlign: "center" }}>
                    {data.data?.vagas?.vagasDisponiveisTotal || 0}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="col-4">
            <table
              style={{
                width: "100%",
                borderCollapse: "collapse",
                marginTop: "20px",
              }}
            >
              <thead>
                <tr>
                  <th
                    colSpan="3"
                    style={{
                      backgroundColor: "#2872ba",
                      textAlign: "center",
                      textTransform: "uppercase",
                      padding: "10px",
                    }}
                  >
                    Convocados
                  </th>
                </tr>
                <tr>
                  <th style={{ padding: "10px", textAlign: "center" }}>
                    Regular
                  </th>
                  <th style={{ padding: "10px", textAlign: "center" }}>PcD</th>
                  <th style={{ padding: "10px", textAlign: "center" }}>
                    Total
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ padding: "10px", textAlign: "center" }}>
                    {data.data?.vagas?.vagasConvocadosRegulares || 0}
                  </td>
                  <td style={{ padding: "10px", textAlign: "center" }}>
                    {data.data?.vagas?.vagasConvocadosPne || 0}
                  </td>
                  <td style={{ padding: "10px", textAlign: "center" }}>
                    {data.data?.vagas?.vagasConvocadosTotal || 0}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="col-4">
            <table
              style={{
                width: "100%",
                borderCollapse: "collapse",
                marginTop: "20px",
              }}
            >
              <thead>
                <tr>
                  <th
                    colSpan="3"
                    style={{
                      backgroundColor: "#2872ba",
                      textAlign: "center",
                      textTransform: "uppercase",
                      padding: "10px",
                    }}
                  >
                    Matriculados
                  </th>
                </tr>
                <tr>
                  <th style={{ padding: "10px", textAlign: "center" }}>
                    Regular
                  </th>
                  <th style={{ padding: "10px", textAlign: "center" }}>PcD</th>
                  <th style={{ padding: "10px", textAlign: "center" }}>
                    Total
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ padding: "10px", textAlign: "center" }}>
                    {data.data?.vagas?.vagasMatriculadosRegulares || 0}
                  </td>
                  <td style={{ padding: "10px", textAlign: "center" }}>
                    {data.data?.vagas?.vagasMatriculadosPne || 0}
                  </td>
                  <td style={{ padding: "10px", textAlign: "center" }}>
                    {data.data?.vagas?.vagasMatriculadosTotal || 0}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}

      {turno > 0 && ocultaBotaoConvocar && (
        <div className="row" style={{ marginBottom: 0 }}>
          <div className="col-12">
            <Alert
              variant="filled"
              severity="info"
              style={{ marginBottom: 20 }}
            >
              Devido ao número de convocados somados ao número de alunos
              matriculados, exceder o total de vagas da sala, o botão atualizar
              foi ocultado para convocação nessa unidade, escolaridade e turno.
            </Alert>
          </div>
        </div>
      )}

      {turno > 0 && (
        <div className="row">
          <div className="col-12">
            <div className="card">
              <Table
                columns={columns}
                data={data.data?.inscricoes ? data.data?.inscricoes : []}
                hasPagination
                limit={limit}
                setLimit={setLimit}
                skip={skip}
                setSkip={setSkip}
                totalItems={data?.total}
                hiddenColluns={["passo2CpfAluno"]}
                overflowUnset={true}
              />
            </div>
          </div>
        </div>
      )}
    </BasePreMatricula>
  );
};

export default PreMatriculaInscricoes;
