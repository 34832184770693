export const unidadesEstudo = [
    { id: 2, nome: "CASA CRECHE Nº 02 – XV DE NOVEMBRO" },
    { id: 3, nome: "CASA CRECHE Nº 03 – OUTEIRO" },
    { id: 4, nome: "CASA CRECHE Nº 04 – OUTEIRO" },
    { id: 5, nome: "CASA CRECHE Nº 05 – OUTEIRO" },
    { id: 6, nome: "CASA CRECHE Nº 06 – FAZENDINHA" },
    { id: 7, nome: "CASA CRECHE Nº 07 – FAZENDINHA" },
    { id: 8, nome: "CASA CRECHE Nº 08 – FAZENDINHA" },
    { id: 9, nome: "CASA CRECHE Nº 09 – MUTIRÃO" },
    { id: 10, nome: "CASA CRECHE Nº 10 – BURACO DO PAU" },
    { id: 11, nome: "CASA CRECHE Nº 11 – XV DE NOVEMBRO" },
    { id: 12, nome: "CASA CRECHE Nº 12 - BANANEIRAS" },
    { id: 13, nome: "CASA CRECHE Nº 13 – RIO DO LIMÃO" },
    { id: 14, nome: "CASA CRECHE Nº 14 - JARDIM CALIFÓRNIA" },
    { id: 15, nome: "CASA CRECHE Nº 15 – CLUBE DOS ENGENHEIROS" },
    { id: 16, nome: "CASA CRECHE Nº 16 – FAZENDINHA" },
    { id: 17, nome: "CASA CRECHE Nº 17 – CENTRO" },
    { id: 18, nome: "CASA CRECHE Nº 18 - PONTE DOS LEITES" },
    { id: 19, nome: "CASA CRECHE Nº 19 - PONTINHA DO OUTEIRO" },
    { id: 20, nome: "CASA CRECHE Nº 20 - BOA PERNA" },
    { id: 21, nome: "CASA CRECHE Nº 21 - BOA PERNA" },
    { id: 22, nome: "CASA CRECHE Nº 22 - RIO DO LIMÃO" },
    { id: 23, nome: "CASA CRECHE Nº 23 - RIO DO LIMÃO" },
    { id: 24, nome: "CASA CRECHE Nº 24 - MUTIRÃO" },
    { id: 25, nome: "CASA CRECHE Nº 25 - JAPÃO" },
    { id: 26, nome: "CASA CRECHE Nº 26 - BOA VISTA" },
    { id: 27, nome: "CASA CRECHE Nº 27 - BURACO DO PAU" },
    { id: 28, nome: "CASA CRECHE Nº 28 - SÃO VICENTE" },
    { id: 29, nome: "CASA CRECHE Nº 29 - VILA CANAÃ" },
    { id: 30, nome: "CASA CRECHE Nº 30 - BANANEIRAS" },
    { id: 31, nome: "CASA CRECHE Nº 31 - IGUABINHA" },
    { id: 32, nome: "CASA CRECHE Nº 32 - XV DE NOVEMBRO" },
    { id: 33, nome: "CASA CRECHE Nº 33 - VILA CAPRI" },
    { id: 34, nome: "CASA CRECHE Nº 34 - BOA PERNA" },
    { id: 35, nome: "CASA CRECHE Nº 35 - RIO DO LIMÃO" },
    { id: 36, nome: "CASA CRECHE Nº 36 - BOA PERNA" },
    { id: 37, nome: "CENTRO MUNICIPAL DE EDUCAÇÃO MÁRIO REVELLES CASTANHO" },
    { id: 38, nome: "CRECHE MUNICIPAL ADALGIRA OLIVEIRA DE ANDRADE" },
    { id: 39, nome: "CRECHE MUNICIPAL ILCA MARIA DUARTE" },
    { id: 40, nome: "CRECHE MUNICIPAL INSPETORA ESCOLAR ROSA MACHADO DOS SANTOS" },
    { id: 41, nome: "CRECHE MUNICIPAL NORMA DA CONCEIÇÃO MARTINS" },
    { id: 42, nome: "CRECHE MUNICIPAL SÃO MAXIMILIANO MARIA KOLBE" },
    { id: 43, nome: "CRECHE ROBERTO DÓRIA GOMES DE MATTOS" },
    { id: 44, nome: "CRECHE VEREADOR CIRALDO FERNANDES DA SILVA" },
    { id: 45, nome: "ESCOLA BILINGUE MUNICIPAL SUELI AMARAL" },
    { id: 46, nome: "ESCOLA ESTADUAL MUNICIPALIZADA FAZENDA JAPÃO" },
    { id: 47, nome: "ESCOLA ESTADUAL MUNICIPALIZADA PARATI" },
    { id: 48, nome: "ESCOLA ESTADUAL MUNICIPALIZADA PRODÍGIO" },
    { id: 49, nome: "ESCOLA MUNICIPAL AGOSTINHO FRANCESCHI" },
    { id: 50, nome: "ESCOLA MUNICIPAL ANDERSON DOMINGUES DE OLIVEIRA" },
    { id: 51, nome: "ESCOLA MUNICIPAL ANDRÉ GOMES DOS SANTOS" },
    { id: 52, nome: "ESCOLA MUNICIPAL ANTONIO ANDRADE MÜLLER" },
    { id: 53, nome: "ESCOLA MUNICIPAL BRUNNO BARRETO NAMETALA" },
    { id: 54, nome: "ESCOLA MUNICIPAL CELINA MESQUITA PEDROSA" },
    { id: 55, nome: "ESCOLA MUNICIPAL DR. JOÃO VASCONCELLOS" },
    { id: 56, nome: "ESCOLA MUNICIPAL ECOLÓGICA PROFESSOR FÁBIO SIQUEIRA" },
    { id: 57, nome: "ESCOLA MUNICIPAL FAUSTINA SILVA CARVALHO" },
    { id: 58, nome: "ESCOLA MUNICIPAL FRANCISCO DOMINGUES NETO" },
    { id: 59, nome: "ESCOLA MUNICIPAL HONORINO COUTINHO" },
    { id: 60, nome: "ESCOLA MUNICIPAL JERÔNIMO CARLOS NASCIMENTO" },
    { id: 61, nome: "ESCOLA MUNICIPAL JOÃO AUGUSTO CHAVES" },
    { id: 62, nome: "ESCOLA MUNICIPAL JOÃO BRITO DE SOUZA" },
    { id: 63, nome: "ESCOLA MUNICIPAL JOAQUINA OLIVEIRA RANGEL" },
    { id: 64, nome: "ESCOLA MUNICIPAL JOSÉ CORRÊA DA FONSECA" },
    { id: 65, nome: "ESCOLA MUNICIPAL NEDIR PAULO BARROSO DA ROSA" },
    { id: 66, nome: "ESCOLA MUNICIPAL MARGARIDA TRINDADE DE DEUS" },
    { id: 67, nome: "ESCOLA MUNICIPAL MARIO BUSCEMA" },
    { id: 68, nome: "ESCOLA MUNICIPAL MENINO ROBSON JUNIOR" },
    { id: 69, nome: "ESCOLA MUNICIPAL PASTOR ALCEBÍADES FERREIRA DE MENDONÇA" },
    { id: 70, nome: "ESCOLA MUNICIPAL PONTA DO CAPIM" },
    { id: 71, nome: "ESCOLA MUNICIPAL PREFEITO ALTEVIR VIEIRA PINTO BARRETO" },
    { id: 72, nome: "ESCOLA MUNICIPAL PROFª HEGLÁUCIA MARIA DE MELLO MATTA" },
    { id: 73, nome: "ESCOLA MUNICIPAL ECOLÓGICA FRANCISCO JOSÉ DE MARINS - 'KIKO'" },
    { id: 74, nome: "ESCOLA MUNICIPAL PROFESSOR DARCY RIBEIRO" },
    { id: 75, nome: "ESCOLA MUNICIPAL PROFESSOR ORLANDO DIAS RIBEIRO" },
    { id: 76, nome: "ESCOLA MUNICIPAL PROFESSOR PEDRO PAULO DE BRAGANÇA PIMENTEL" },
    { id: 77, nome: "ESCOLA MUNICIPAL PROFESSOR RAYMUNDO MAGNO CAMARÃO" },
    { id: 78, nome: "ESCOLA MUNICIPAL PROFESSORA NAIR VALLADARES" },
    { id: 79, nome: "ESCOLA MUNICIPAL SARA URRUTIA BAPTISTA" },
    { id: 80, nome: "ESCOLA MUNICIPAL SINVAL PINTO DE FIGUEIREDO" },
    { id: 81, nome: "ESCOLA MUNICIPAL TONINHO SENRA" },
    { id: 82, nome: "ESCOLA MUNICIPAL VER. ALCEBÍADES CARVALHO DAS FLORES" },
    { id: 83, nome: "ESCOLA MUNICIPAL VEREADOR EDEMUNDO PEREIRA DE SÁ CARVALHO" },
    { id: 84, nome: "ESCOLA MUNICIPAL VEREADOR MOYSÉS RAMALHO" },
    { id: 85, nome: "PRAÇA ESCOLA ECOLÓGICA MUNICIPAL DR. FERNANDO CARVALHO" },
    { id: 86, nome: "PRAÇA ESCOLA IGUABINHA - MARCOS HERON CORRÊA" },
    { id: 87, nome: "PRAÇA ESCOLA ITATIQUARA - PREF. AFRÂNIO VALADARES" },
    { id: 88, nome: "PRAÇA ESCOLA PRAIA SECA - COMANDANTE SÉRGIO RIBEIRO" },
    { id: 89, nome: "ESCOLA MUNICIPAL PROF. CARLOS LEAL" },
    { id: 90, nome: "CASA CRECHE Nº 37 - IGUABINHA (DESATIVADA)" },
    { id: 91, nome: "CASA CRECHE Nº 38 – XV DE NOVEMBRO" },
    { id: 92, nome: "CASA CRECHE Nº 39 – BOA PERNA" },
    { id: 93, nome: "CASA CRECHE Nº 40 – JARDIM SÃO PAULO" },
    { id: 94, nome: "CASA CRECHE Nº 41 – XV DE NOVEMBRO" },
    { id: 95, nome: "CASA CRECHE Nº 52 - AREAL" },
    { id: 96, nome: "CASA CRECHE Nº 43 - FAZENDINHA" },
    { id: 97, nome: "CASA CRECHE Nº 44 - FAZENDINHA" },
    { id: 98, nome: "CASA CRECHE Nº 45 – PARQUE HOTEL" },
    { id: 99, nome: "CASA CRECHE Nº 46 – FAZENDINHA" },
    { id: 100, nome: "CASA CRECHE Nº 47 – RIO DO LIMÃO" },
    { id: 101, nome: "CASA CRECHE Nº 48 – PARATI" },
    { id: 102, nome: "CASA CRECHE Nº 49 - FAZENDINHA" },
    { id: 103, nome: "CASA CRECHE Nº 50 - FAZENDINHA" },
    { id: 104, nome: "CASA CRECHE Nº 51 - XV DE NOVEMBRO" },
    { id: 107, nome: "CASA CRECHE Nº 42 - VIADUTO" },
    { id: 108, nome: "CASA CRECHE Nº 53 - FAZENDINHA" },
    { id: 110, nome: "ESCOLA BILÍNGUE MUNICIPAL PROFESSOR JOÃO RAPOSO" },
    { id: 111, nome: "CASA CRECHE Nº 54 - FAZENDINHA" },
    { id: 112, nome: "CASA CRECHE Nº 55 - NOVO HORIZONTE" },
    { id: 113, nome: "ESCOLA BILÍNGUE MUNICIPAL OSCARINO PEREIRA DE ANDRADE" },
    { id: 114, nome: "CRECHE MUNICIPAL ALEXANDRE MOREIRA MARTINS" },
    { id: 115, nome: "COMPLEXO EDUCACIONAL SALINAS" },
  ];
  