import { useMemo, useEffect, useState } from 'react'
import { useForm } from "react-hook-form";
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import Breadcrumb from '../../../../../components/breadcrumb';
import BasePreMatricula from '../../basePreMatricula';
import urlsConfiguracoes from '../../../../configuracoes/urlsConfiguracoes';
import { postStatusPreMatricula, getHistorico } from '../../../../../services/processos/preMatricula2024';
import { useMutation, useQuery } from 'react-query';
import FormInput from '../../../../../components/formInput';
import { store } from '../../../../../store/index'
import Table from '../../../../../components/table';

const PreMatriculaInscricoesHistorico = () => {
  const { id } = useParams();
  const { state } = useLocation();
  const [status, setStatus] = useState(0)
  const navigate = useNavigate();

  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(20);
  const [data, setData] = useState([]);

  const renderStatus = (status) => {
    switch (status) {
        case 1:
            return 'PRÉ-MATRÍCULA';
        case 2:
            return 'CONVOCADO';
        case 3:
            return 'MATRICULADO';
        case 4:
            return 'NÃO COMPARECIDO';
        case 5:
            return 'INDEFERIDO';
        case 6:
            return 'CANCELADO';
        case 7:
            return 'DESISTÊNCIA';
        case 8:
            return 'MATRICULADO POR OUTRA OPÇÃO';
        case 9:
            return 'CONVOCADO POR OUTRA OPÇÃO';
        default:
            return '';
    }
};

  const { isError, refetch } = useQuery(
  'getHistorico', () => getHistorico(id, state.unidade),
  {
    retry: 0,
    enabled: state.unidade > 0,
    onSuccess: (data) => {
      setData(data);
    }
  });

  const columns = useMemo(
    () => [
      {
        Header: 'Data', accessor: 'criacao',
        Cell: ({ row }) => (
          row.original.criacao.split('T')[0].split('-').reverse().join('/') + ' às ' + row.original.criacao.split('T')[1].split('.')[0]
        )
      },
      {
        Header: 'Status', accessor: 'status',
        Cell: ({ row }) => (
          renderStatus(row.original.status)
        )
      },
      { Header: 'Por', accessor: 'usuario' },
      { Header: 'Observação', accessor: 'observacao' },
    ],
    []
  )
  

  const { mutate, isLoading } = useMutation(postStatusPreMatricula, {
    onSuccess: (data) => {
      toast.success('Status atualizado com sucesso.');
      navigate(-1);
    },
    onError: (error) => {
      if (error.response) {
        const { message } = error.response.data;
        toast.error(message);
      } else if (error.request) {
        toast.error('Erro ao tentar indeferir, tente novamente mais tarde.');
      } else {
        toast.error(error.message);
      }
    }
  });

  const currentUser = store.getState()['user']['user']


  const { register, handleSubmit, setValue, formState: { isValid, errors }, watch } = useForm({ mode: "onSubmit" });

  // PARA TESTAR A INTEGRAÇÃO, SÓ DESCOMENTAR A LINHA "MUTATE" ABAIXO
  const onSubmit = data => {

    console.log(data)
    mutate(data);
  }

  useEffect(() => {
    setValue('id', id)
    setValue('nome', state.nome)
    setValue('cpf', state.cpf)
    setValue('unidade', state.unidade)
    setValue('usuarioId', currentUser?.servidorId)
  }, [setValue])

  return (
    <BasePreMatricula isEvasaoAlunos>
      <Breadcrumb title={'ATUALIZAR STATUS'} itens={['PRÉ-MATRICULA 2024', 'INSCRIÇÕES', 'ATUALIZAR STATUS']} />
      <form id='form' onSubmit={handleSubmit(onSubmit)}>
      
        <div className='row'>
          <div className='col-12'>
            <div className='card'>
              <div className="card-body">
                <><div className='row'>

<div className='col-4'>
                <FormInput
                  register={register}
                  errors={errors}
                  group=""
                  atribute="cpf"
                  label="CPF"
                  readOnly
                />
              </div>

              <div className='col-4'>
                <FormInput
                  register={register}
                  errors={errors}
                  group=""
                  atribute="nome"
                  label="Nome"
                  readOnly
                />
              </div>

              <div className='col-4'>
                <FormInput
                  register={register}
                  errors={errors}
                  group=""
                  atribute="unidade"
                  label="Unidade Opção"
                  readOnly
                />
              </div>


</div>
                  <div className='row'  style={{marginTop: 20}}>
                  



                    <div className="col-6">
                <div className="form-group">
                  <label htmlFor="status">Status<span style={{ color: 'red', marginLeft: '4px' }}>*</span></label>
                  <select
                    className="form-select"
                    id="status"
                    style={{ maxHeight: '100px', overflow: 'auto' }}
                    {...register('status', { required: true, valueAsNumber: true })} 
                  >
                   <option value={0}></option>
                   <option value={1}>PRÉ-MATRÍCULA</option>
                  {/* <option value={2}>CONVOCADO</option> */}
                  <option value={3}>MATRICULADO</option>
                  <option value={4}>NÃO COMPARECIDO</option>
                  <option value={5}>INDEFERIDO</option>
                  <option value={6}>CANCELADO</option>
                  <option value={7}>DESISTÊNCIA</option>
                  <option value={8}>MATRICULADO POR OUTRA OPÇÃO</option>
                  </select>
                  {errors?.cidade?.type === 'required' && (
                    <span className="text-danger">Campo obrigatório</span>
                  )}
                </div>
              </div>

              <div className='col-6'>
                <FormInput
                  register={register}
                  errors={errors}
                  group=""
                  atribute="motivo"
                  label="Motivo"
                />
              </div>


                    
                  </div>

                  <div 
                    style={{ 
                      display: 'flex', 
                      justifyContent: 'flex-end',
                      marginRight:30,
                      marginTop: 20,
                     }}>
                    <button 
                    style={{marginRight: 20}} 
                    type='button' 
                    className='btn btn-warning' 
                    onClick={() => navigate(-1)}>
                        Voltar
                      </button>
                      {/* <button type='submit' className='btn btn-success'>
                        Atualizar
                      </button> */}
                      
                    </div>

                    <div className="row" style={{marginTop: 20}}>
        <div className="col-12">
          <div className="card">
            <Table
              columns={columns}
              data={data.data ? data.data : []}
              hasPagination
              limit={limit}
              setLimit={setLimit}
              skip={skip}
              setSkip={setSkip}
              totalItems={data?.total}
              hiddenColluns={["id", "passo2CpfAluno"]}
            />
          </div>
        </div>
      </div>

             

                </>
              </div>

      
              
            </div>
          </div>
        </div>
      </form>
    </BasePreMatricula>
  )

};

export default PreMatriculaInscricoesHistorico;
