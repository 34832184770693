import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { CircularProgress, Box, Typography, Button } from "@mui/material";
import Etapa7 from "../components/Etapa7";
import { pesquisarPorIdPreMatricula } from "../../../../../services/processos/preMatricula";
import html2pdf from "html2pdf.js";
import { useNavigate } from "react-router-dom";
import urlsPreMatricula from "../../../../processos/preMatricula/urlsPreMatricula";
import { usePDF } from 'react-to-pdf';

const FormularioPreMatriculaVisualizacaoAraruama = () => {
  const { id } = useParams(); // Extract 'id' from the route
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const contentRef = useRef(null); // Reference to the content for PDF export
  const { toPDF, targetRef } = usePDF({filename: `pre-matricula-${id}.pdf`});

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await pesquisarPorIdPreMatricula(id); // Replace with your actual API call
        setData(response?.data || null);
      } catch (err) {
        console.error("Error fetching data:", err);
        setError("Failed to fetch data. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchData();
    }
  }, [id]);

  const handleExportToPDF = () => {
    if (contentRef.current) {
      const options = {
        margin: 10,
        filename: `pre-matricula-${id}.pdf`,
        html2canvas: { scale: 2 },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      };
      html2pdf().from(contentRef.current).set(options).save();
    }
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          textAlign: "center",
        }}
      >
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      </Box>
    );
  }

  if (!data) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          textAlign: "center",
        }}
      >
        <Typography variant="h6">Não foram encontrados dados para inscrição: {id}</Typography>
      </Box>
    );
  }

  return (
    <Box>
      <Box sx={{ margin: "20px auto", textAlign: "right" }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => toPDF()}
          sx={{ textTransform: "none", fontWeight: "bold" }}
        >
          Baixar PDF
        </Button>
        <Button
          variant="contained"
          color="warning"
          onClick={() => navigate(-1)}
          sx={{ textTransform: "none", fontWeight: "bold" }}
        >
          Voltar
        </Button>
      </Box>

      {/* Content to Export */}
      <Box
        ref={targetRef}
        sx={{
          maxWidth: "1100px",
          margin: "0 auto",
          padding: "20px 0",
          borderRadius: 4,
          backgroundColor: "#fff",
        }}
      >
        {/* Logo */}
        <Box
          sx={{
            margin: "20px auto",
            textAlign: "center",
          }}
        >
          <img
            src="/assets/images/prefeituras/seduc.png"
            alt="Logo"
            style={{ maxWidth: "100px", marginBottom: "10px" }}
          />
          <Typography variant="h4" fontWeight="bold" gutterBottom>
            {data.passo3NomeCompleto || "--"}
          </Typography>
          <Typography variant="body1" gutterBottom>
            <b>INSCRIÇÃO PRÉ-MATRÍCULA 2025: </b> {data?.id || ""}
          </Typography>
          <Typography variant="body2" gutterBottom>
            <b>DATA INSCRIÇÃO:</b>{" "}
            {data.passo7DataCriacao
              ? new Date(data.passo7DataCriacao).toLocaleString("pt-BR")
              : "--"}
          </Typography>
        </Box>

        {/* Render the Etapa7 component */}
        <Etapa7 data={data} aviso={false} />
      </Box>
    </Box>
  );
};

export default FormularioPreMatriculaVisualizacaoAraruama;
